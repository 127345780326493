import React, { useState } from 'react';
import {
  Container,
  Flex,
  Heading,
  Text,
  Center,
  Box,
  Image,
  useMediaQuery,
} from '@chakra-ui/react';
import LoginForm from '../../../components/forms/LoginForm';
import ResetPassForm from '../../../components/forms/ResetPassForm';
import { useAuth } from '../../../providers/auth';
import imageBackground from '../../../assets/images/login-background.png';
import logo from '../../../assets/images/logo.png';
import logoWithName from '../../../assets/images/logo-with-name.png';
import Loader from '../../../components/Loader';

export default function LoginPage() {
  const { loading } = useAuth();

  if (loading) {
    return <Loader />;
  }

  const [isSmallerThan1280] = useMediaQuery('(max-width: 767px)');

  const [showResetPass, setShowResetPass] = useState<boolean>(false);

  const onChangeResetState = () => {
    setShowResetPass(!showResetPass);
  };

  return (
    <Flex
      direction={['column', 'row']}
      h="100vh"
      backgroundImage={imageBackground}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundPosition="bottom center"
    >
      {!isSmallerThan1280 && (
        <Box w="full" minH="100vh" pl="7.3%" pt="6.79%">
          <Box display="flex" flexDirection="row" alignItems="center" mb={27}>
            <Image src={logo} mr={18} w="70px" />
            <Text fontSize={30} color="#FFFFFF" fontFamily="Roobert-SemiBold">
              Você está nadando contra
              <br /> ou a favor da maré?
            </Text>
          </Box>
          <Heading fontSize={60} color="#FFFFFF" fontFamily="Roobert-SemiBold">
            Método NADE
          </Heading>
          <Text fontSize={30} color="#FFFFFF" fontFamily="Roobert-Regular">
            O resultado do seu negócio
            <br /> cada vez melhor
          </Text>
        </Box>
      )}
      <Center
        w="full"
        minH="100vh"
        backgroundColor="rgba(0,0,0,0.4)"
        backdropFilter="blur(8px)"
        borderBottomLeftRadius={39}
        borderTopLeftRadius={39}
        justifyContent="center"
      >
        {showResetPass ? (
          <Container maxW="540px">
            <Image src={logoWithName} mb={70} />
            <Heading fontSize={33} color="#FFFFFF" fontFamily="Roobert-Regular" mb="6px">
              Redefinir senha
            </Heading>
            <Text fontSize={20} color="#FFFFFF" fontFamily="Roobert-Regular" mb="40px">
              Digite o e-mail cadastrado para receber a nova senha
            </Text>
            <ResetPassForm onChange={onChangeResetState} />
          </Container>
        ) : (
          <Container maxW="540px">
            <Image src={logoWithName} mb={70} />
            <Heading fontSize={33} color="#FFFFFF" fontFamily="Roobert-Regular" mb="6px">
              Entrar
            </Heading>
            <Text fontSize={20} color="#FFFFFF" fontFamily="Roobert-Regular" mb="40px">
              Digite suas informações para acessar
            </Text>
            <LoginForm onChange={onChangeResetState} />
          </Container>
        )}
      </Center>
    </Flex>
  );
}
