import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useAuth } from '../providers/auth';
import { OpenRoutes } from './open.routes';
import { PrivateRoutes } from './private.routes';
import PrivateUser from './privateUser';
import { RequireAuth } from './require.auth';

const AppRouter = () => {
  const { user } = useAuth();
  return (
    <Routes>
      {OpenRoutes.map((item, key) => (
        <Route key={Number(key)} path={item.path} element={<item.component />} />
      ))}
      <Route path="/" element={<RequireAuth />}>
        {PrivateRoutes.map((item, key) => {
          const ComponentCreate = item.componentCreate as unknown as React.ElementType;
          const ComponentShow = item.componentShow as unknown as React.ElementType;
          const ComponentEdit = item.componentEdit as unknown as React.ElementType;
          const Component = item.component as unknown as React.ElementType;
          return (
            <>
              {Component && (
                <Route
                  key={Number(key)}
                  path={item.path}
                  element={
                    <PrivateUser user={user} currentRoute={item.path}>
                      <Component />
                    </PrivateUser>
                  }
                />
              )}
              {ComponentCreate && (
                <Route
                  path={`${item.path}/create`}
                  element={
                    <PrivateUser user={user} currentRoute={item.path}>
                      <ComponentCreate />
                    </PrivateUser>
                  }
                />
              )}
              {ComponentShow && (
                <Route
                  path={`${item.path}/:itemId`}
                  element={
                    <PrivateUser user={user} currentRoute={item.path}>
                      <ComponentShow />
                    </PrivateUser>
                  }
                />
              )}
              {ComponentEdit && (
                <Route
                  path={`${item.path}/:itemId/edit`}
                  element={
                    <PrivateUser user={user} currentRoute={item.path}>
                      <ComponentEdit />
                    </PrivateUser>
                  }
                />
              )}
            </>
          );
        })}
      </Route>
    </Routes>
  );
};

export default AppRouter;
