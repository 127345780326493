/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Flex,
  Heading,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useMediaQuery,
  Text,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { InputHookForm } from '../../inputs/InputHookForm';
import { FormButton } from '../../buttons/FormButton';
import { useFetch } from '../../../hooks/useFetch';
import { useUnit } from '../../../hooks/useUnit';
import { SwitchHookForm } from '../../inputs/SwitchHookForm';
import { SelectInputForm } from '../../inputs/SelectInputForm';
import { CheckboxGroupInputForm } from '../../inputs/CheckboxGroupInputForm';
import BackArrow from '../../../assets/svgs/BackArrow';
import { DataGrid } from '../../datagrid';
import SectorsForm from '../SectorsForm';

interface UserFormProps {
  itemIdUrl?: string;
}

interface FormInputsProps {
  name: string;
  value: string | any[];
  placeholder?: string;
  label: string | undefined;
  yup: any;
}

export default function IndicatorsForm({ itemIdUrl }: UserFormProps) {
  const yupschema: any = {};

  const [defaultValues, setDefaultValues] = useState<any>({});
  const defaultValuesSchema: any = defaultValues;
  const baseUrl = '/v1/admin/users';
  const baseUrlThird = '/v1';
  const { requestApi: reqApiUsers, data: dataUsers, loading } = useFetch(baseUrl);
  const [activeTab, setActiveTab] = useState(0);
  const [sector_form_id, setSectorFormId] = useState(0);
  const [formInputs, setFormInputs] = useState<FormInputsProps[]>([]);
  const location = useLocation();
  const sectorId = location.pathname.split('/')[2];
  const {
    requestApi: requestApiSectors,
    data: dataSectors,
    loading: loadingSector,
  } = useFetch(`${baseUrlThird}/numbers/forms/${sectorId}`);
  const navigate = useNavigate();

  formInputs.forEach(object => {
    /* yupschema[object.name] = object.yup; */
    defaultValuesSchema[object.name] = object.value;
  });
  const schema = Yup.object().shape(yupschema);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValuesSchema,
  });

  useEffect(() => {
    if (Object.keys(defaultValues).length > 0) {
      reset(defaultValuesSchema);
    }
  }, [defaultValues]);
  useEffect(() => {
    reset(defaultValuesSchema);
  }, [activeTab]);

  useEffect(() => {
    requestApiSectors('get');
  }, []);

  const name = formInputs.filter(field => field.name === 'name').shift();
  const email = formInputs.filter(field => field.name === 'email').shift();
  const unitsAndClients = formInputs.filter(field => field.name === 'units').shift();
  const status = formInputs.filter(field => field.name === 'status').shift();
  const password = formInputs.filter(field => field.name === 'password').shift();
  const confirmPassword = formInputs.filter(field => field.name === 'confirm_password').shift();
  const group_id = formInputs.filter(field => field.name === 'group_id').shift();
  const titles = dataSectors?.data ? [...dataSectors?.data?.map(sec => sec?.title)] : [''];
  const onSubmit = async (values: any) => {
    await requestPostApi(
      Object.keys(defaultValues).length > 0 ? 'put' : 'post',
      {
        response: { ...values },
        sector_form_id,
      },
      Object.keys(defaultValues).length > 0
        ? `${baseUrlThird}/numbers/responses/${defaultValues.id}`
        : null,
    );
    reset({});
    setDefaultValues({});
    requestApiSectors('get');
    requestApi('get', { params: { sector_form_id } });
  };
  const onBackArrow = () => {
    navigate('/insert-numbers');
  };

  const {
    requestApi,
    data,
    loading: loadingResponses,
    error,
  } = useFetch(`${baseUrlThird}/numbers/responses`);
  const {
    requestApi: requestPostApi,
    data: dataReqs,
    loading: loadingReqs,
  } = useFetch(`${baseUrlThird}/numbers/responses`);

  const [dataTable, setDataTable] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  useEffect(() => {
    requestApi('get', { params: { sector_form_id } });
  }, [sector_form_id]);
  useEffect(() => {
    if (!data) return;
    const table = data?.data?.map(response => {
      return { ...response.request_json, id: response.id };
    });
    setDataTable(table);
    setDataColumns(data?.names_and_labels);
  }, [data]);
  useEffect(() => {
    if (!dataSectors || sector_form_id !== 0) return;
    setSectorFormId(dataSectors?.data[0]?.id);
  }, [dataSectors]);

  const onEdit = (params: any) => {
    setDefaultValues(params);
    console.log('PARAMS', params);
  };
  const didDelete = () => {
    requestApi('get', { params: { sector_form_id } });
  };
  return (
    <>
      <Heading as="h1" size="lg" mb={9} display="flex" alignItems="center">
        <BackArrow style={{ marginRight: 15, cursor: 'pointer' }} onClick={onBackArrow} />{' '}
        {dataSectors?.data ? [...dataSectors?.data?.map(sec => sec?.title)][activeTab] : ['']}
      </Heading>
      <Box h="100%" maxWidth="530px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Tabs
            position="relative"
            variant="unstyled"
            mb="10"
            onChange={n => {
              setActiveTab(n);
              setSectorFormId(dataSectors?.data[n]?.id);
            }}
            lazyBehavior="unmount"
            isLazy
          >
            <TabList>
              {titles.map(route => (
                <Tab key={route}>{route}</Tab>
              ))}
            </TabList>
            {!loadingSector && (
              <TabIndicator mt="-1.5px" height="2px" bg="cyan.500" borderRadius="1px" />
            )}
            <TabPanels>
              {!loading &&
                !loadingSector &&
                dataSectors?.data?.map(route => (
                  <TabPanel key={route.id}>
                    <Flex>
                      {route?.fields?.map(field => (
                        <InputHookForm
                          register={register}
                          name={field.name}
                          errors={errors}
                          label={field.label}
                          type={field.type}
                          max={100000000000000}
                          w="180px"
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          width="180px"
                          mr="10px"
                        />
                      ))}
                    </Flex>
                  </TabPanel>
                ))}
            </TabPanels>
          </Tabs>

          <Flex>
            <Button
              bg="#4AC1F0"
              color="#FCFCFD"
              w="190px"
              py="7"
              fontSize="20px"
              fontWeight="700"
              type="submit"
              isLoading={isSubmitting || loading}
              mt={0}
            >
              Aplicar
            </Button>
          </Flex>
        </form>
      </Box>
      <Box display="flex" alignItems="center" mt={10}>
        <Heading as="h1" size="lg" sx={{ fontSize: '25px', mr: 1.5 }}>
          Histórico{' '}
        </Heading>
        <Text as="h1" size="lg" sx={{ fontSize: '25px' }}>
          {' '}
          - Cultura de clima
        </Text>
      </Box>
      <DataGrid
        columns={dataColumns}
        rows={dataTable}
        requestApi={requestPostApi}
        error={error}
        baseUrl={`${baseUrlThird}/numbers/responses`}
        data={data}
        editAction={onEdit}
        didDelete={didDelete}
        /*  hasCreate
         createDescription="setor" */
        modalForm={SectorsForm}
        /*   formatColum={[
          {
            field: 'client',
            formatField: value => {
              return value.name;
            },
          },
        ]} */
        loading={loadingResponses || loadingReqs}
        rowActions={{ show: false, edit: true, delete: true }}
      />
    </>
  );
}
