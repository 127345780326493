import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetch } from '../../hooks/useFetch';
import { useUnit } from '../../hooks/useUnit';

export default function SelectUnitPage() {
  const baseUrlClient = '/v1/admin/clients';
  const { requestApi: reqApiClient, data: dataClient } = useFetch(baseUrlClient);
  const { updateClient, updateUnit } = useUnit();
  const baseUrlUnit = '/v1/admin/units';
  const { requestApi: reqApiUnit, data: dataUnit } = useFetch(baseUrlUnit);
  const navigate = useNavigate();

  useEffect(() => {
    reqApiClient('get', {
      params: { page: 1, per_page: 100 },
    });
  }, []);

  useEffect(() => {
    if (dataClient?.data?.length > 0) {
      updateClient(Number(dataClient?.data[0]?.id)).then(() => {
        reqApiUnit('get', null, `${baseUrlUnit}?client_id=${dataClient?.data[0]?.id}`);
      });
    }
  }, [dataClient]);

  useEffect(() => {
    if (dataUnit?.data?.length > 0) {
      updateUnit(Number(dataUnit?.data[0]?.id)).then(() => {
        navigate('/numeros');
      });
    }
  }, [dataUnit]);
}
