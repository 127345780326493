/* eslint-disable react/no-children-prop */
import React from 'react';
import { Button } from '@chakra-ui/react';

export const FormButton = ({ children, ...props }: any) => {
  return (
    <Button
      mt={20}
      colorScheme="teal"
      h={46}
      borderRadius={12}
      fontFamily="Roobert-Regular"
      color={props.variant ? '#00B2DD' : '#FFF'}
      bgColor={props.variant ? '' : '#00B2DD'}
      {...props}
    >
      {children}
    </Button>
  );
};
