/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';
import InputMask from 'react-input-mask';

interface InputInterface {
  name: string | undefined | any;
  label?: string | undefined;
  placeholder?: string | undefined;
  register: any;
  errors: any;
  colorPlaceholder?: string;
  icon?: JSX.Element;
  mask?: string;
  maskChar?: string;
  disable?: boolean;
  type?: string;
  pl?: boolean;
  w?: string;
  max?: number;
}

export const InputHookForm = ({
  name,
  label,
  placeholder,
  register,
  errors,
  icon,
  colorPlaceholder = '',
  mask,
  disable,
  maskChar,
  type,
  w,
  pl = true,
  max,
  ...props
}: InputInterface) => {
  return (
    <FormControl isInvalid={!!errors[name]} mb={2} w={w}>
      <FormLabel fontWeight="bold" mb={0} htmlFor={name}>
        {label}
      </FormLabel>
      <InputGroup alignItems="center" alignContent="center">
        <InputLeftElement pointerEvents="none" color="#FFF" fontSize="1.2em" mt="0.7em" ml={5}>
          {icon}
        </InputLeftElement>
        {type === 'number' ? (
          <NumberInput
            variant="unstyled"
            id={name}
            min={1}
            max={max || 100}
            w={w || '100%'}
            focusBorderColor={errors[name] ? '#B22222' : '#00FF00'}
            color={colorPlaceholder}
            height={65}
            borderColor="#e2e8f0"
            borderWidth={1}
            pl={pl ? (icon ? '100px' : '60px') : '0px'}
            type={type}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            borderRadius={6}
            disabled={disable}
            maskChar={maskChar || null}
            {...register(name)}
            {...props}
          >
            <NumberInputField placeholder={placeholder} color="#718096" />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        ) : (
          <Input
            id={name}
            focusBorderColor={errors[name] ? '#B22222' : '#00FF00'}
            placeholder={placeholder}
            color={colorPlaceholder}
            height={65}
            pl={pl ? (icon ? '100px' : '60px') : '10px'}
            as={!!mask && InputMask}
            mask={mask || ''}
            type={type}
            disabled={disable}
            maskChar={maskChar || null}
            {...register(name)}
            {...props}
          />
        )}
      </InputGroup>
      {errors[name] && <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>}
    </FormControl>
  );
};
