/* eslint-disable prettier/prettier */
/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputHookForm } from '../../inputs/InputHookForm';
import { FormButton } from '../../buttons/FormButton';
import { useFetch } from '../../../hooks/useFetch';
import { SelectInputForm } from '../../inputs/SelectInputForm';
import { MaskedInputForm } from '../../inputs/MaskedInputForm';
import { CheckboxInputForm } from '../../inputs/CheckboxInputForm';

interface DecisionFormProps {
  defaultValues?: any;
  onClose: any;
  itemIdUrl: string;
}

interface FormInputsProps {
  name: string;
  value: string | any[];
  placeholder?: string;
  label: string | undefined;
  yup: any;
  mask?: string;
}

export default function UnitsForm({ defaultValues = {}, onClose, itemIdUrl }: DecisionFormProps) {
  const [isSmallerThan1280] = useMediaQuery('(max-width: 767px)');
  const yupschema: any = {};
  const defaultValuesSchema: any = {};
  const { requestApi, data, loading } = useFetch('v1/admin/units');
  const { requestApi: reqApiClient, data: dataClient } = useFetch('v1/admin/clients');

  const formInputs: FormInputsProps[] = [
    {
      name: 'client_id',
      value: defaultValues.client_id ?? '',
      placeholder: 'Cliente',
      label: undefined,
      mask: '',
      yup: Yup.string().required('Este campo é obrigatório!'),
    },
    {
      name: 'name',
      value: defaultValues.name ?? '',
      placeholder: 'Apelido',
      label: undefined,
      mask: '',
      yup: Yup.string().required('Este campo é obrigatório!'),
    },
    {
      name: 'type',
      value: defaultValues.type ?? '',
      placeholder: 'Tipo de unidade',
      label: undefined,
      mask: '',
      yup: Yup.string().required('Este campo é obrigatório!'),
    },
    {
      name: 'contact_name',
      value: defaultValues.contact_name ?? '',
      placeholder: 'Nome do responsável',
      label: undefined,
      mask: '',
      yup: Yup.string().required('Este campo é obrigatório!'),
    },
    {
      name: 'phone',
      value: defaultValues.phone ?? '',
      placeholder: 'Telefone',
      label: undefined,
      mask: '(99) 99999-9999',
      yup: Yup.string().required('Este campo é obrigatório!'),
    },
    {
      name: 'comments',
      value: defaultValues.comments ?? '',
      placeholder: 'Comentários',
      label: undefined,
      mask: '',
      yup: Yup.string(),
    },
    {
      name: 'link_numbers',
      value: defaultValues.link_numbers ?? '',
      placeholder: 'Link Números',
      label: undefined,
      mask: '',
      yup: Yup.string().required('Este campo é obrigatório!'),
    },
    {
      name: 'link_analysis',
      value: defaultValues.link_analysis ?? '',
      placeholder: 'Link Análises',
      label: undefined,
      mask: '',
      yup: Yup.string().required('Este campo é obrigatório!'),
    },
    {
      name: 'show_numbers',
      value: defaultValues.show_numbers ?? false,
      label: undefined,
      yup: Yup.boolean(),
    },
    {
      name: 'show_analysis',
      value: defaultValues.show_analysis ?? false,
      label: undefined,
      yup: Yup.boolean(),
    },
    {
      name: 'show_decisions',
      value: defaultValues.show_decisions ?? false,
      label: undefined,
      yup: Yup.boolean(),
    },
    {
      name: 'show_emotions',
      value: defaultValues.show_emotions ?? false,
      label: undefined,
      yup: Yup.boolean(),
    },
  ];

  const client_id = formInputs.find(field => field.name === 'client_id');
  const name = formInputs.find(field => field.name === 'name');
  const type = formInputs.find(field => field.name === 'type');
  const contact_name = formInputs.find(field => field.name === 'contact_name');
  const phone = formInputs.find(field => field.name === 'phone');
  const comments = formInputs.find(field => field.name === 'comments');
  const link_numbers = formInputs.find(field => field.name === 'link_numbers');
  const link_analysis = formInputs.find(field => field.name === 'link_analysis');
  const show_numbers = formInputs.find(field => field.name === 'show_numbers');
  const show_analysis = formInputs.find(field => field.name === 'show_analysis');
  const show_decisions = formInputs.find(field => field.name === 'show_decisions');
  const show_emotions = formInputs.find(field => field.name === 'show_emotions');

  formInputs.forEach(object => {
    yupschema[object.name] = object.yup;
    defaultValuesSchema[object.name] = object.value;
  });
  const schema = Yup.object().shape(yupschema);

  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValuesSchema,
  });

  const onSubmit = async (values: any) => {
    requestApi(defaultValues.name ? 'put' : 'post', values, defaultValues.name ? itemIdUrl : null);
  };

  useEffect(() => {
    if (data?.msg === 'Registered successfully' || data?.msg === 'Updated successfully') {
      onClose();
    }
  }, [data?.msg]);

  useEffect(() => {
    if (defaultValues.name) {
      reset(defaultValuesSchema);
    }
  }, [defaultValues]);

  useEffect(() => {
    if (data?.msg === 'Registered successfully') {
      onClose();
    }
  }, [data?.msg]);

  useEffect(() => {
    reqApiClient('get', {
      params: { page: 1, per_page: 100 },
    });
  }, []);

  return (
    <Box h="100%" pr="41%" overflowY="auto">
      <form onSubmit={handleSubmit(onSubmit)}>
        <SelectInputForm
          name={client_id?.name}
          label={client_id?.label}
          options={[
            ...(dataClient?.data?.map(item => ({
              label: item?.name,
              value: item?.id,
            })) ?? []),
          ]}
          placeholder={client_id?.placeholder}
          control={control}
          errors={errors}
        />
        <InputHookForm
          name={name?.name}
          label={name?.label}
          placeholder={name?.placeholder}
          register={register}
          errors={errors}
        />
        <SelectInputForm
          name={type?.name}
          label={type?.label}
          options={[
            {
              label: 'Matriz',
              value: 'Matriz',
            },
            {
              label: 'Filial',
              value: 'Filial',
            },
            {
              label: 'Franqueado',
              value: 'Franqueado',
            },
            {
              label: 'Franqueadora',
              value: 'Franqueadora',
            },
            {
              label: 'Unidade',
              value: 'Unidade',
            },
          ]}
          placeholder={type?.placeholder}
          control={control}
          errors={errors}
        />
        <InputHookForm
          name={contact_name?.name}
          label={contact_name?.label}
          placeholder={contact_name?.placeholder}
          register={register}
          errors={errors}
        />
        <MaskedInputForm
          name={phone?.name}
          label={phone?.label}
          placeholder={phone?.placeholder}
          control={control}
          errors={errors}
          mask={phone?.mask}
        />
        <InputHookForm
          name={comments?.name}
          label={comments?.label}
          placeholder={comments?.placeholder}
          register={register}
          errors={errors}
        />
        <InputHookForm
          name={link_numbers?.name}
          label={link_numbers?.label}
          placeholder={link_numbers?.placeholder}
          register={register}
          errors={errors}
        />
        <InputHookForm
          name={link_analysis?.name}
          label={link_analysis?.label}
          placeholder={link_analysis?.placeholder}
          register={register}
          errors={errors}
        />

        <Box>
          <Text fontWeight="bold" mb={2}>
            Menus da unidade
          </Text>
          <Flex>
            <CheckboxInputForm
              name={show_numbers?.name}
              label={show_numbers?.label}
              optionLabel="Números"
              control={control}
              errors={errors}
            />
            <CheckboxInputForm
              name={show_analysis?.name}
              label={show_analysis?.label}
              optionLabel="Análises"
              control={control}
              errors={errors}
            />
            <CheckboxInputForm
              name={show_decisions?.name}
              label={show_decisions?.label}
              optionLabel="Decisões"
              control={control}
              errors={errors}
            />
            <CheckboxInputForm
              name={show_emotions?.name}
              label={show_emotions?.label}
              optionLabel="Emoções"
              control={control}
              errors={errors}
            />
          </Flex>
        </Box>

        <Flex justify="space-between" mb={30}>
          <FormButton
            mt={4}
            variant="outline"
            isLoading={isSubmitting || loading}
            onClick={onClose}
            w={isSmallerThan1280 ? 280 : 250}
          >
            Fechar
          </FormButton>
          <FormButton
            mt={4}
            isLoading={isSubmitting || loading}
            type="submit"
            w={isSmallerThan1280 ? 280 : 250}
          >
            Salvar
          </FormButton>
        </Flex>
      </form>
    </Box>
  );
}
