import { Box } from '@chakra-ui/react';
import React from 'react';
// import { useTranslation } from 'react-i18next';
import { DataGrid } from '../../components/datagrid';
import { useFetch } from '../../hooks/useFetch';
import { SearchFilter } from '../../components/inputs/filters/search';
import UserForm from '../../components/forms/UserForm';

export default function UsersPage() {
  // const { t } = useTranslation();
  const baseUrl = '/v1/admin/users';
  const { requestApi, data, loading, error } = useFetch(baseUrl);

  const columns = [
    { name: 'id', label: 'Id' },
    { name: 'name', label: 'Nome' },
    { name: 'email', label: 'Email' },
    { name: 'status', label: 'Status' },
  ];

  const status = v => {
    if (v === true) {
      return (
        <span
          style={{ color: 'white', padding: 4, background: 'green', borderRadius: 5, fontSize: 14 }}
        >
          Ativo
        </span>
      );
    }
    return (
      <span
        style={{ color: 'white', padding: 4, background: 'red', borderRadius: 5, fontSize: 14 }}
      >
        Inativo
      </span>
    );
  };

  return (
    <Box>
      <DataGrid
        columns={columns}
        rows={data?.data}
        requestApi={requestApi}
        error={error}
        baseUrl={baseUrl}
        data={data}
        hasCreate
        createDescription="usuário"
        modalForm={UserForm}
        loading={loading}
        rowActions={{ show: false, edit: true, delete: true }}
        formatColum={[
          {
            field: 'status',
            formatField: status,
          },
        ]}
        headerFilters={[
          {
            name: 'search',
            filterComponent: SearchFilter,
            placeholder: 'Buscar Usuário',
          },
        ]}
      />
    </Box>
  );
}
