/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prettier/prettier */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Flex, useMediaQuery } from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { InputHookForm } from '../../inputs/InputHookForm';
import { FormButton } from '../../buttons/FormButton';
import { ImageInputForm } from '../../inputs/ImageInputForm';
import { useFetch } from '../../../hooks/useFetch';

interface DecisionFormProps {
  defaultValues?: any;
}

interface FormInputsProps {
  name: string;
  value: string;
  placeholder?: string;
  label: string | undefined;
  yup: any;
}

export default function ProfileForm({ defaultValues = {} }: DecisionFormProps) {
  const [isSmallerThan1280] = useMediaQuery('(max-width: 767px)');
  const navigate = useNavigate();
  const [images, setImages] = useState<any>('');

  const yupschema: any = {};
  const defaultValuesSchema: any = {};
  const baseUrl = '/v1/client';
  const { requestApi, loading, data } = useFetch(baseUrl);

  const formInputs: FormInputsProps[] = [
    {
      name: 'name',
      value: defaultValues.name ?? '',
      placeholder: 'Nome',
      label: undefined,
      yup: Yup.string().required('Este campo é obrigatório!'),
    },
    {
      name: 'email',
      value: defaultValues.email ?? '',
      placeholder: 'Email',
      label: undefined,
      yup: Yup.string().required('Este campo é obrigatório!'),
    },
    {
      name: 'password',
      value: '',
      placeholder: 'Senha',
      label: undefined,
      yup: Yup.string(),
    },
    {
      name: 'confirm_password',
      value: '',
      placeholder: 'Confirmar senha',
      label: undefined,
      yup: Yup.string().oneOf([Yup.ref('password'), null], 'As senhas não são iguais'),
    },
    {
      name: 'image',
      value: defaultValues.image ?? null,
      placeholder: 'Imagem',
      label: undefined,
      yup: Yup.mixed().nullable(),
    },
    // {
    //   name: 'status',
    //   value: defaultValues.status ?? '',
    //   placeholder: 'Status',
    //   label: undefined,
    //   yup: Yup.string().required('Este campo é obrigatório!'),
    // },
  ];

  formInputs.forEach((object) => {
    yupschema[object.name] = object.yup;
    defaultValuesSchema[object.name] = object.value;
  });
  const schema = Yup.object().shape(yupschema);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValuesSchema,
  });

  const name = formInputs.filter((field) => field.name === 'name').shift();
  const email = formInputs.filter((field) => field.name === 'email').shift();
  const password = formInputs.filter((field) => field.name === 'password').shift();
  const confirmPassword = formInputs.filter((field) => field.name === 'confirm_password').shift();
  const image = formInputs.filter((field) => field.name === 'image').shift();
  // const status = formInputs.filter(field => field.name === 'status').shift();

  const onSubmit = async (values: any) => {
    await requestApi('put', { ...values, group_id: defaultValues?.group?.id, image: _ });
    if (images) {
      await requestApi('put', { image: images.toString() }, `${baseUrl}/images`);
    }
  };

  useEffect(() => {
    if (data?.status === 'OK' && !loading) {
      navigate(0);
    }
  }, [data?.status, loading]);

  useEffect(() => {
    if (defaultValues.name) {
      reset(defaultValuesSchema);
    }
  }, [defaultValues]);

  return (
    <Box h="100%" maxWidth="530px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex direction="column" justify="space-evenly">
          <ImageInputForm
            defaultVal={defaultValues?.image}
            setImages={setImages}
            name={image?.name}
            label={image?.label}
            register={register}
            watch={watch}
            errors={errors}
          />

          <InputHookForm
            name={name?.name}
            label={name?.label}
            placeholder={name?.placeholder}
            register={register}
            errors={errors}
          />

          <InputHookForm
            name={email?.name}
            label={email?.label}
            placeholder={email?.placeholder}
            register={register}
            errors={errors}
          />

          <InputHookForm
            name={password?.name}
            label={password?.label}
            placeholder={password?.placeholder}
            register={register}
            errors={errors}
            type="password"
          />

          <InputHookForm
            name={confirmPassword?.name}
            label={confirmPassword?.label}
            placeholder={confirmPassword?.placeholder}
            register={register}
            errors={errors}
            type="password"
          />

          <FormButton
            mt={4}
            isLoading={isSubmitting || loading}
            type="submit"
            w={isSmallerThan1280 ? 280 : 250}
          >
            Salvar usuário
          </FormButton>
        </Flex>
      </form>
    </Box>
  );
}
