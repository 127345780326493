import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Layout from '../components/layout';
import Loader from '../components/Loader';
import { useAuth } from '../providers/auth';
import { PrivateRoutes } from './private.routes';

export const RequireAuth = () => {
  const { signed, loading } = useAuth();
  const location = useLocation();
  const noMargin = PrivateRoutes.find(route => route.path.includes(location.pathname))?.noMargin;
  /* const noMargin = location.pathname.includes('') */
  if (loading) {
    return <Loader />;
  }

  return signed ? (
    <Layout noMargin={noMargin}>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};
