import { AxiosError } from 'axios';
import React, { createContext, ReactElement, useContext, useEffect, useState } from 'react';
import { DataType, ErrorType } from '../hooks/useFetch';
import { api } from '../services/api';

interface SettingsContextType {
  requestGlobal(): Promise<void> | object;
  settings: DataType | null;
  error: ErrorType | string | null | unknown;
  loading: boolean;
}

interface AuxProps {
  children: ReactElement | ReactElement[];
}

const SettingsContext = createContext<SettingsContextType>({} as SettingsContextType);

export const SettingsProvider: React.FC<AuxProps> = ({ children }) => {
  const [settings, setSettings] = useState<DataType | null>(null);
  const [error, setError] = useState<ErrorType | string | null | unknown>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      requestGlobal();
    };

    // call the function
    fetchData();
  }, []);

  const requestGlobal: SettingsContextType['requestGlobal'] = async () => {
    try {
      setLoading(true);
      setError(true);

      // request
      const res = await api.get('/v1/settings', {});

      setSettings(res.data);
      setLoading(false);
      setError(null);
    } catch (e) {
      const err = e as AxiosError;

      setError(err.response?.data);
      setLoading(false);

      throw new Error();
    }
  };

  return (
    <SettingsContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        requestGlobal,
        settings,
        error,
        loading,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export function useSettings() {
  const context = useContext(SettingsContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider.');
  }

  return context;
}
