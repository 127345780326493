/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Flex, useMediaQuery } from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { InputHookForm } from '../../inputs/InputHookForm';
import { FormButton } from '../../buttons/FormButton';
import { useFetch } from '../../../hooks/useFetch';
import { useUnit } from '../../../hooks/useUnit';
import { SwitchHookForm } from '../../inputs/SwitchHookForm';
import { SelectInputForm } from '../../inputs/SelectInputForm';
import { CheckboxGroupInputForm } from '../../inputs/CheckboxGroupInputForm';

interface UserFormProps {
  defaultValues?: any;
  onClose: any;
  itemIdUrl: string;
}

interface FormInputsProps {
  name: string;
  value: string | any[];
  placeholder?: string;
  label: string | undefined;
  yup: any;
}

export default function UserForm({ defaultValues = {}, onClose, itemIdUrl }: UserFormProps) {
  const [isSmallerThan1280] = useMediaQuery('(max-width: 767px)');
  const yupschema: any = {};
  const defaultValuesSchema: any = {};
  const { unit: unitId } = useUnit();
  const baseUrl = '/v1/admin/users';
  const baseUrlSecondary = '/v1/admin';
  const { requestApi: reqApiUsers, data: dataUsers, loading } = useFetch(baseUrl);
  const { requestApi: reqApiUnits, data: dataUnits } = useFetch(`${baseUrlSecondary}/units`);
  const { requestApi: reqApiSectors, data: dataSectors } = useFetch(`${baseUrlSecondary}/sectors`);
  const [userTypeState, setUserTypeState] = useState<any>('');
  const [editPassword, setEditPassword] = useState(false);

  const formInputs: FormInputsProps[] = [
    {
      name: 'name',
      value: defaultValues.name ?? '',
      placeholder: 'Nome',
      label: '',
      yup: Yup.string().required('Este campo é obrigatório!'),
    },
    {
      name: 'email',
      value: defaultValues.email ?? '',
      placeholder: 'Email',
      label: '',
      yup: Yup.string().email('Email inválido!').required('Este campo é obrigatório!'),
    },
    {
      name: 'units',
      value: [...(defaultValues.units?.map(dataUnit => dataUnit.id.toString()) ?? [])],
      label: 'Unidades e clientes',
      yup: userTypeState > '2' ? Yup.array().min(1, 'Selecione pelo menos uma unidade!') : null,
    },
    {
      name: 'sectors',
      value: [...(defaultValues.sectors?.map(dataUnit => dataUnit.id.toString()) ?? [])],
      label: 'Setores',
      yup: userTypeState > '2' ? Yup.array().min(1, 'Selecione pelo menos um setor!') : null,
    },
    {
      name: 'status',
      value: defaultValues.status ?? true,
      label: 'Status',
      yup: Yup.boolean(),
    },
    {
      name: 'group_id',
      value: defaultValues.group?.id ?? '',
      placeholder: 'Selecione o tipo de usuário',
      label: '',
      yup: Yup.string().required('Obrigatório'),
    },
    {
      name: 'password',
      value: '',
      placeholder: 'Insira a nova senha',
      label: '',
      yup: editPassword
        ? Yup.string()
            .required('Obrigatório')
            .min(6, 'Senha muito curta')
            .max(30, 'Senha muito longa')
        : null,
    },
    {
      name: 'confirm_password',
      value: '',
      placeholder: 'Digite a senha novamente',
      label: '',
      yup: editPassword
        ? Yup.string()
            .required('Obrigatório')
            .oneOf([Yup.ref('password'), null], 'As senhas não são iguais')
        : null,
    },
  ];

  formInputs.forEach(object => {
    yupschema[object.name] = object.yup;
    defaultValuesSchema[object.name] = object.value;
  });
  const schema = Yup.object().shape(yupschema);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValuesSchema,
  });

  useEffect(() => {
    if (defaultValues.name) {
      reset(defaultValuesSchema);
    }
  }, [defaultValues]);

  useEffect(() => {
    if (watch('group_id')) {
      setUserTypeState(watch('group_id'));
    }
  }, [watch('group_id')]);

  useEffect(() => {
    reqApiUnits('get', {
      params: { page: 1, per_page: 100 },
    });
    reqApiSectors('get', {
      params: { page: 1, per_page: 100 },
    });
  }, [unitId]);
  /* useEffect(() => {
  }, [unitId]); */

  useEffect(() => {
    if (dataUsers?.msg === 'Registered successfully' || dataUsers?.msg === 'Updated successfully') {
      onClose();
    }
  }, [dataUsers?.msg]);

  const name = formInputs.filter(field => field.name === 'name').shift();
  const email = formInputs.filter(field => field.name === 'email').shift();
  const unitsAndClients = formInputs.filter(field => field.name === 'units').shift();
  const unitSectors = formInputs.filter(field => field.name === 'sectors').shift();
  const status = formInputs.filter(field => field.name === 'status').shift();
  const password = formInputs.filter(field => field.name === 'password').shift();
  const confirmPassword = formInputs.filter(field => field.name === 'confirm_password').shift();
  const group_id = formInputs.filter(field => field.name === 'group_id').shift();

  const onSubmit = async (values: any) => {
    const sectors = [...(values.sectors?.map(sector => Number(sector)) ?? [])];
    const units = [...(values.units?.map(dataUnit => Number(dataUnit)) ?? [])];
    const clientsDup = [
      ...(units.map(
        item => dataUnits?.data?.find(dataUnit => dataUnit.id === Number(item))?.client.id,
      ) ?? []),
    ];
    const clients = clientsDup.filter((c, index) => {
      return clientsDup.indexOf(c) === index;
    });
    reqApiUsers(
      defaultValues.name ? 'put' : 'post',
      {
        ...values,
        clients: values.group_id > '2' ? clients : _,
        units: values.group_id > '2' ? units : _,
        sectors: values.group_id > '2' ? sectors : _,
        password_update: editPassword && defaultValues.name ? true : _,
        password: editPassword && defaultValues.name ? values.password : _,
        confirm_password: _,
      },
      defaultValues.name ? itemIdUrl : null,
    );
  };

  return (
    <Box h="100%" maxWidth="530px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex direction="column" justify="space-evenly">
          <InputHookForm
            name={name?.name}
            label={name?.label}
            placeholder={name?.placeholder}
            register={register}
            errors={errors}
          />

          <Flex>
            <Box flex={5} mr="10px">
              <InputHookForm
                name={email?.name}
                label={email?.label}
                placeholder={email?.placeholder}
                register={register}
                errors={errors}
              />
            </Box>

            <Box flex={1} mr="10px">
              <SwitchHookForm
                name={status?.name}
                label={status?.label}
                control={control}
                errors={errors}
              />
            </Box>
          </Flex>

          {defaultValues.name && (
            <FormButton
              mt={2}
              mb={4}
              variant="outline"
              isLoading={isSubmitting}
              onClick={() => setEditPassword(old => !old)}
              w={isSmallerThan1280 ? 280 : 250}
            >
              Editar senha
            </FormButton>
          )}

          {editPassword && defaultValues.name && (
            <>
              <InputHookForm
                name={password?.name}
                label={password?.label}
                placeholder={password?.placeholder}
                register={register}
                errors={errors}
              />
              <InputHookForm
                name={confirmPassword?.name}
                label={confirmPassword?.label}
                placeholder={confirmPassword?.placeholder}
                register={register}
                errors={errors}
              />
            </>
          )}

          <SelectInputForm
            name={group_id?.name}
            label={group_id?.label}
            options={[
              {
                label: 'Cliente',
                value: 3,
              },
              {
                label: 'Administrador',
                value: 2,
              },
            ]}
            placeholder={group_id?.placeholder}
            control={control}
            errors={errors}
          />

          {watch('group_id') > '2' && (
            <CheckboxGroupInputForm
              name={unitsAndClients?.name}
              label={unitsAndClients?.label}
              options={[
                ...(dataUnits?.data?.map(unit => ({
                  label: (
                    <>
                      <strong>Cliente:</strong> {unit.client.name} - <strong>Unidade:</strong>{' '}
                      {unit.name}
                    </>
                  ),
                  value: unit.id?.toString(),
                })) ?? []),
              ]}
              control={control}
              errors={errors}
            />
          )}
          {watch('group_id') > '2' && (
            <CheckboxGroupInputForm
              name={unitSectors?.name}
              label={unitSectors?.label}
              options={[
                ...(dataSectors?.data
                  ?.filter(sector => watch('units').includes(sector?.unit?.id?.toString()))
                  ?.map(unit => ({
                    label: <>Setor {unit.description}</>,
                    value: unit.id?.toString(),
                  })) ?? []),
              ]}
              control={control}
              errors={errors}
            />
          )}

          <Flex justify="space-between" mb="20px">
            <FormButton
              mt={4}
              variant="outline"
              isLoading={isSubmitting}
              onClick={onClose}
              w={isSmallerThan1280 ? 280 : 250}
            >
              Fechar
            </FormButton>
            <FormButton
              mt={4}
              isLoading={isSubmitting || loading}
              type="submit"
              w={isSmallerThan1280 ? 280 : 250}
            >
              Salvar usuário
            </FormButton>
          </Flex>
        </Flex>
      </form>
    </Box>
  );
}
