import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { STORAGE_KEYS } from '../constants/Config';
import { getAuthStorage, setAuthStorage } from '../services/storage';
import { useFetch } from './useFetch';

export const useUnit = () => {
  const [unit, setUnit] = useState<number | null>(null);
  const [unitData, setUnitData] = useState<any>(null);
  const [client, setClient] = useState<number | null>(null);
  const [clientData, setClientData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const baseUrl = '/v1/admin';
  const { requestApi: reqApiClient, data: dataClient } = useFetch(`${baseUrl}/clients`);
  const { requestApi: reqApiUnit, data: dataUnit } = useFetch(`${baseUrl}/units`);

  useEffect(() => {
    reqApiClient('get', {
      params: { page: 1, per_page: 100 },
    });
    reqApiUnit('get', {
      params: { page: 1, per_page: 100 },
    });
  }, [unit, client]);

  useEffect(() => {
    if (dataClient) {
      setClientData(dataClient.data.find(item => item.id === client));
    }
  }, [dataClient]);

  useEffect(() => {
    if (dataUnit) {
      setUnitData(dataUnit.data.find(item => item.id === unit));
    }
  }, [dataUnit]);

  useEffect(() => {
    getClient();
    getUnit();
  }, [location.pathname]);

  const getClient = async () => {
    setLoading(true);
    if (typeof client !== 'number') {
      const storageClient = await getAuthStorage(STORAGE_KEYS.CLIENT);
      if (storageClient) {
        await setClient(Number(storageClient));
      }
    }
    setLoading(false);
  };

  const getUnit = async () => {
    setLoading(true);
    if (typeof unit !== 'number') {
      const storageUnit = await getAuthStorage(STORAGE_KEYS.UNIT);
      if (storageUnit) {
        await setUnit(Number(storageUnit));
      }
    }
    setLoading(false);
  };

  const updateClient = async (unitId: number) => {
    setLoading(true);
    setUnit(unitId);
    const res = await setAuthStorage(unitId, STORAGE_KEYS.CLIENT);
    setLoading(false);
    return res;
  };

  const updateUnit = async (unitId: number) => {
    setLoading(true);
    setUnit(unitId);
    const res = await setAuthStorage(unitId, STORAGE_KEYS.UNIT);
    setLoading(false);
    return res;
  };

  return { updateUnit, updateClient, unit, unitData, client, clientData, loading };
};
