/* eslint-disable react/no-children-prop */
import { ViewIcon } from '@chakra-ui/icons';
import { IconButton, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

export const ViewButton = ({ onClick }: any) => {
  return (
    <IconButton
      onClick={onClick}
      color={useColorModeValue('theme.light.secondary', 'theme.dark.secondary')}
      icon={<ViewIcon h={5} w={5} />}
      size="sm"
      mr={2}
      variant="ghost"
      aria-label=""
    />
  );
};
