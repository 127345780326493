/* eslint-disable react/no-children-prop */
import React from 'react';
import { Button } from '@chakra-ui/react';
// import { AddIcon } from '@chakra-ui/icons';

export const CreateButton = ({ onClick, description, ...rest }: any) => {
  return (
    <Button
      onClick={onClick}
      borderRadius="100px"
      w="250px"
      fontWeight="medium"
      fontSize="18px"
      justifyContent="space-evenly"
      variant="outline"
      boxShadow="0px 3px 6px #00000029"
      leftIcon={
        <svg
          width="25"
          height="25"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.3613 7.3056H10.6944V1.63886C10.6944 -0.546286 7.30544 -0.546286 7.30544 1.63886V7.3056H1.63849C-0.546164 7.3056 -0.546164 10.6944 1.63849 10.6944H7.30544V16.3611C7.30544 18.5463 10.6944 18.5463 10.6944 16.3611V10.6944H16.3613C18.5462 10.6944 18.5462 7.3056 16.3613 7.3056Z"
            fill="url(#paint0_linear_609_190)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_609_190"
              x1="17.295"
              y1="8.99859"
              x2="-0.00112439"
              y2="8.99859"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#9747FF" />
              <stop offset="1" stopColor="#00CEFF" />
            </linearGradient>
          </defs>
        </svg>
      }
      {...rest}
    >
      {description ? `Criar ${description.toLowerCase()}` : 'Criar'}
    </Button>
  );
};
