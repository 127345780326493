/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import {
  useColorModeValue,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Text,
  Avatar,
} from '@chakra-ui/react';
import { BiImageAdd } from 'react-icons/bi';

interface InputProps {
  defaultVal: any;
  setImages: any;
  name: string | undefined | any;
  label: string | undefined;
  register: any;
  watch: any;
  errors: any;
}

export const ImageInputForm = ({
  defaultVal,
  setImages,
  name,
  label,
  register,
  watch,
  errors,
}: InputProps) => {
  const [preview, setPreview] = useState<any>(undefined);

  const convert2base64 = file => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setImages(reader.result);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (watch(name)?.length > 0 && typeof watch(name) === 'object') {
      convert2base64(watch(name)[0] as any);
      const objectUrl = URL.createObjectURL(watch(name)[0]);
      setPreview(objectUrl);

      return () => URL.revokeObjectURL(objectUrl);
    }
    setPreview(undefined);
  }, [watch(name)]);

  return (
    <FormControl isInvalid={!!errors[name]} mb={2}>
      <FormLabel fontWeight="bold" mb={1} htmlFor={name}>
        {label}
      </FormLabel>
      <Avatar size="2xl" src={preview ?? defaultVal?.original} />
      <FormControl>
        <label htmlFor={name} style={{ display: 'block', width: '200px' }}>
          <Flex
            w="200px"
            h="40px"
            bg={useColorModeValue('theme.light.grey', 'theme.dark.grey')}
            cursor="pointer"
            align="center"
            justify="center"
            _hover={{ opacity: 0.5 }}
            borderRadius="5px"
            boxShadow="0px 3px 6px #00000029"
          >
            <Icon as={BiImageAdd} mr="5px" fontSize="25px" />
            <Text fontWeight="bold">Selecionar imagem</Text>
          </Flex>
        </label>
        <input id={name} type="file" hidden accept="image/*" {...register(name)} />
      </FormControl>
      {errors[name] && <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>}
    </FormControl>
  );
};
