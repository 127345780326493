import React from 'react';
import { Box, CircularProgress, Text } from '@chakra-ui/react';

const Loader = () => (
  <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
    <Box marginTop="100px" display="flex" flexDirection="column" alignItems="center">
      <CircularProgress color="theme.light.primary" isIndeterminate size={150} />
      <Text
        style={{
          fontWeight: 'bold',
          fontSize: '25px',
          marginTop: '30px',
        }}
      >
        Carregando...
      </Text>
    </Box>
  </Box>
);

export default Loader;
