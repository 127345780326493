/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { Checkbox, FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';

interface SelectInterface {
  name: string | undefined | any;
  label: string | undefined;
  placeholder?: string | undefined;
  control: any;
  errors: any;
  optionLabel: any;
  disable?: boolean;
  type?: string;
}

export const CheckboxInputForm = ({
  name,
  label,
  control,
  errors,
  optionLabel,
}: SelectInterface) => {
  return (
    <FormControl isInvalid={!!errors[name]} mb={2}>
      <FormLabel fontWeight="bold" mb={2} htmlFor={name}>
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange, ...rest } }) => (
          <Checkbox {...rest} isChecked={value} onChange={e => onChange(e.target.checked)}>
            {optionLabel}
          </Checkbox>
        )}
      />
      {errors[name] && <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>}
    </FormControl>
  );
};
