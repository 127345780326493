import React from 'react';
import { Box } from '@chakra-ui/react';

export const Strike = () => {
  return (
    <Box
      sx={{
        height: '1px',
        backgroundColor: '#000',
        width: '80%',
        position: 'absolute',
        top: '50%',
        left: '10%',
      }}
    />
  );
};
