import { useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import React, { createContext, ReactElement, useContext, useEffect, useState } from 'react';
import { api } from '../services/api';
import { getAuthStorage, removeAuthStorage, setAuthStorage } from '../services/storage';

interface UserImageType {
  large: string;
  medium: string;
  original: string;
  small: string;
}

interface UserType {
  id: number;
  cpf: string;
  cell_phone: string;
  email: string;
  genre: string;
  name: string;
  status: boolean;
  images: UserImageType | null;
}

interface ErrorType {
  status: string;
  msg: string;
}

interface AuthContextData {
  signed: boolean;
  user: UserType | object | any;
  loading: boolean;
  error: ErrorType | string | null | unknown;
  // eslint-disable-next-line no-unused-vars
  Login(user: object): Promise<void> | any;
  Logout(): void;
  getUser(): any;
}

interface AuxProps {
  children: ReactElement | ReactElement[];
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC<AuxProps> = ({ children }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [user, setUser] = useState<UserType | object | null>(null);
  const [error, setError] = useState<ErrorType | string | null | unknown>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const verifyLogged = async () => {
      if (user == null) {
        const storageAuth = await getAuthStorage();
        if (storageAuth) {
          api.defaults.headers.common.Authorization = `Bearer ${storageAuth.access_token}`;
          await getUser();
        }
      }
      setLoading(false);
    };
    verifyLogged();
  }, [user]);

  const getUser: AuthContextData['getUser'] = async () => {
    const res = await api.get('/v1/client');

    if (res.status !== 200) {
      return false;
    }
    // set user
    setUser(res.data.data);
    return user;
  };

  const Login: AuthContextData['Login'] = async data => {
    try {
      setLoading(true);
      setError(null);

      // set header authorization
      api.defaults.headers.common.Authorization = process.env.REACT_APP_BASIC_ADMIN as string;

      // request
      const res = await api.post('/auth/token', {
        grant_type: 'password',
        ...data,
      });

      if (res.status === 200) {
        // save token in storage
        await setAuthStorage(res.data);

        // set token in header
        api.defaults.headers.common.Authorization = `Bearer ${res.data.access_token}`;

        // get user
        await getUser();

        setLoading(false);
        navigate('/', { replace: true });
        return (
          toast({
            title: 'Sucesso!',
            description: 'Login efetuado com sucesso.',
            status: 'success',
            duration: 9000,
            isClosable: true,
          }),
          res
        );
      }
      return toast({
        title: 'Algo deu errado!',
        description: 'Tente novamente ou entre em contato com o suporte.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      const err = e as AxiosError;

      setError(err.response?.data);
      setLoading(false);

      return toast({
        title: 'Algo deu errado!',
        description: 'Credenciais incorretas.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  function Logout() {
    removeAuthStorage();
    setUser(null);
  }

  return (
    <AuthContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ signed: Boolean(user), user, loading, error, Login, Logout, getUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider.');
  }

  return context;
}
