import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './translations/en.json';
import pt from './translations/pt.json';

const resources = {
  en,
  pt,
};

export const availableLanguages = Object.keys(resources);

i18n.use(initReactI18next).init({
  resources,
  defaultNS: 'common',
  lng: 'pt',
  fallbackLng: 'pt',
});
