import React from 'react';
// import { useTranslation } from 'react-i18next';
import { DataGrid } from '../../components/datagrid';
import { useFetch } from '../../hooks/useFetch';
import { SearchFilter } from '../../components/inputs/filters/search';
import ClientsForm from '../../components/forms/ClientsForm';

export default function ClientsPage() {
  // const { t } = useTranslation();
  const baseUrl = '/v1/admin/clients';
  const { requestApi, data, loading, error } = useFetch(baseUrl);

  const columns = [
    { name: 'id', label: 'Id' },
    { name: 'name', label: 'Nome Fantasia' },
    { name: 'company_name', label: 'Razão Social' },
    { name: 'contact_name', label: 'Nome do contato' },
    { name: 'phone', label: 'Telefone' },
    { name: 'comments', label: 'Comentários' },
  ];

  return (
    <div className="App">
      <DataGrid
        columns={columns}
        rows={data?.data}
        requestApi={requestApi}
        error={error}
        baseUrl={baseUrl}
        data={data}
        hasCreate
        createDescription="cliente"
        modalForm={ClientsForm}
        loading={loading}
        rowActions={{ show: false, edit: true, delete: true }}
        headerFilters={[
          {
            name: 'search',
            filterComponent: SearchFilter,
            placeholder: 'Buscar Cliente',
          },
        ]}
      />
    </div>
  );
}
