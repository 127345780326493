/* eslint-disable react/no-children-prop */
// import { EditIcon } from '@chakra-ui/icons';
import { IconButton, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

export const EditButton = ({ onClick }: any) => {
  return (
    <IconButton
      onClick={onClick}
      color={useColorModeValue('theme.light.secondary', 'theme.dark.secondary')}
      icon={
        <svg
          width="36"
          height="40"
          viewBox="0 0 36 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25.1156 15.0708L25.1157 15.0707C25.5124 14.674 25.731 14.1465 25.731 13.586C25.731 13.0255 25.5124 12.498 25.1157 12.1013L23.5297 10.5153C23.133 10.1186 22.6055 9.9 22.045 9.9C21.4845 9.9 20.9571 10.1185 20.5613 10.5143L9.9294 21.1132L9.9 21.1425V21.184V25.599V25.699H10H14.413H14.4543L14.4836 25.6698L25.1156 15.0708ZM10 27.499H9.9V27.599V29.599V29.699H10H26H26.1V29.599V27.599V27.499H26H10ZM20.5976 13.5841L22.0449 12.1413L23.4904 13.5849L22.0421 15.0277L20.5976 13.5841ZM12.1 23.499V22.0555L19.0399 15.1373L20.4845 16.5819L13.5457 23.499H12.1Z"
            fill="url(#paint0_linear_616_139)"
            stroke="url(#paint1_linear_616_139)"
            strokeWidth="0.2"
          />
          <defs>
            <linearGradient
              id="paint0_linear_616_139"
              x1="26.4"
              y1="21.2694"
              x2="9.6"
              y2="21.2694"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00CEFF" />
              <stop offset="1" stopColor="#9747FF" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_616_139"
              x1="26.4"
              y1="21.2694"
              x2="9.6"
              y2="21.2694"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00CEFF" />
              <stop offset="1" stopColor="#9747FF" />
            </linearGradient>
          </defs>
        </svg>
      }
      size="sm"
      mr={2}
      variant="ghost"
      aria-label=""
    />
  );
};
