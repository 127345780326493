/* eslint-disable react/no-children-prop */
import React from 'react';
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';

export const SearchFilter = ({ onChange, ...rest }: any) => {
  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none" children={<FaSearch color="gray.300" />} />
      <Input
        placeholder="Buscar"
        borderRadius="full"
        variant="outline"
        boxShadow="0px 3px 6px #00000029"
        onChange={e => onChange(e.target.value)}
        {...rest}
      />
    </InputGroup>
  );
};
