import React from 'react';
import { Box, FormControl, FormErrorMessage, FormLabel, Select } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';

interface SelectInterface {
  name: string | undefined | any;
  label: string | undefined;
  placeholder?: string | undefined;
  control: any;
  errors: any;
  options: any;
  colorPlaceholder?: string;
  icon?: JSX.Element;
  mask?: string;
  maskChar?: string;
  disable?: boolean;
  type?: string;
}

export const SelectInputForm = ({
  name,
  label,
  placeholder,
  control,
  errors,
  options,
  ...props
}: SelectInterface) => {
  return (
    <FormControl isInvalid={!!errors[name]} mb={2}>
      <FormLabel fontWeight="bold" mb={0} htmlFor={name}>
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, ref } }) => (
          <Box
            w="100%"
            borderColor={errors[name] ? 'red' : '#e2e8f0'}
            borderWidth={errors[name] ? 2 : 1}
            height={65}
            pl="60px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            borderRadius={6}
            // mb={3}
          >
            <Select
              ref={ref}
              variant="unstyled"
              color="#718096"
              placeholder={placeholder}
              value={options?.find(c => c.value === value)?.value}
              onChange={onChange}
              {...props}
            >
              {options?.map(opt => (
                <option key={opt?.value} value={opt?.value}>
                  {opt?.label}
                </option>
              ))}
            </Select>
          </Box>
        )}
      />
      {/* <Box
        w="100%"
        borderColor={errors[name] ? 'red' : '#e2e8f0'}
        borderWidth={errors[name] ? 2 : 1}
        height={65}
        pl="60px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        borderRadius={6}
        // mb={3}
      >
        <Select
          variant="unstyled"
          color="#718096"
          placeholder={placeholder}
          {...register(name)}
          {...props}
        >
          {options?.map(opt => (
            <option key={opt?.value} value={opt?.value}>
              {opt?.label}
            </option>
          ))}
        </Select>
      </Box> */}
      {errors[name] && <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>}
    </FormControl>
  );
};
