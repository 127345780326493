import { AxiosStatic } from 'axios';
import { getAuthStorage, setAuthStorage } from './storage';

// eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
const axios = require('axios') as AxiosStatic;

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.response.use(
  response => {
    return response;
  },
  // eslint-disable-next-line @typescript-eslint/no-shadow
  async error => {
    if (error.response.status === 401) {
      if (
        error.config.url.includes('auth') === true ||
        error.config.url.includes('recover') === true
      ) {
        return Promise.reject(error);
      }
      // eslint-disable-next-line no-use-before-define
      const token = await refreshToken();

      // eslint-disable-next-line no-param-reassign
      error.config.headers.Authorization = `Bearer ${token}`;

      return api(error.config);
    }

    return Promise.reject(error);
  },
);

const refreshToken = async () => {
  try {
    const storageAuth = await getAuthStorage();
    if (storageAuth) {
      api.defaults.headers.common.Authorization = `Bearer ${storageAuth.refresh_token}`;
    }
    const res = await api.post('/auth/refresh');
    if (res.status === 200) {
      await setAuthStorage(res.data);
    } else {
      return Promise.reject();
    }
    const token = res.data.access_token;
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return token;
  } catch (error) {
    return error;
  }
};
