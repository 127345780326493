import * as React from 'react';
import { SVGProps } from 'react';

const BackArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} fill="none" {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7.75 14.75 1 8l6.75-6.75M1.937 8h13.688"
    />
  </svg>
);
export default BackArrow;
