export const colors = {
  theme: {
    light: {
      body: 'white',
      primary: '#343036',
      secondary: '#1A181B',
      tertiary: '#B186FC',
    },
    dark: {
      body: '#1A181B',
      primary: '#343036',
      secondary: '#1A181B',
      tertiary: '#B186FC',
    },
  },

  text: {
    light: {
      primary: 'white',
      secondary: '#B186FC',
    },
    dark: {
      primary: '#3C3C3C',
      secondary: '#B186FC',
    },
  },
};
