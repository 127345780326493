import React, { useEffect } from 'react';
// import { useTranslation } from 'react-i18next';
import { DataGrid } from '../../components/datagrid';
import { useFetch } from '../../hooks/useFetch';
import { SearchFilter } from '../../components/inputs/filters/search';
import SectorsForm from '../../components/forms/SectorsForm';

export default function SectorsPage() {
  // const { t } = useTranslation();
  const baseUrl = '/v1/admin';
  const { requestApi, data, loading, error } = useFetch(`${baseUrl}/sectors`);
  const { requestApi: reqApiUnit, data: dataUnit /* loading:loadingUnit */ } = useFetch(
    `${baseUrl}/units`,
  );
  useEffect(() => {
    reqApiUnit('get');
  }, []);
  const columns = [
    { name: 'unit', label: 'Cliente' },
    { name: 'unit_id', label: 'Unidade' },
    { name: 'description', label: 'Descrição do setor' },
    { name: 'responsible_name', label: 'Nome do responsável' },
  ];

  return (
    <div className="App">
      <DataGrid
        columns={columns}
        rows={data?.data}
        requestApi={requestApi}
        error={error}
        baseUrl={`${baseUrl}/sectors`}
        data={data}
        hasCreate
        createDescription="setor"
        modalForm={SectorsForm}
        formatColum={[
          {
            field: 'unit',
            formatField: value => {
              return value?.client?.name || '';
            },
          },
          {
            field: 'unit_id',
            formatField: value => {
              return (
                dataUnit?.data.find(unit => unit.id.toString() === value.toString())?.name || ''
              );
            },
          },
        ]}
        loading={loading}
        rowActions={{ show: false, edit: true, delete: true }}
        headerFilters={[
          {
            name: 'search',
            filterComponent: SearchFilter,
            placeholder: 'Buscar Setores',
          },
        ]}
      />
    </div>
  );
}
