import React from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import InputMask from 'react-input-mask';
import { Controller } from 'react-hook-form';

interface InputInterface {
  name: string | undefined | any;
  label: string | undefined;
  placeholder?: string | undefined;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  mask: string | undefined;
  isRequired?: boolean;
  control: any;
  errors: any;
}

export const MaskedInputForm = ({
  name,
  label,
  placeholder,
  iconLeft,
  iconRight,
  mask,
  isRequired,
  control,
  errors,
  ...props
}: InputInterface) => {
  return (
    <FormControl isRequired={isRequired} isInvalid={!!errors[name]} mb={2}>
      <FormLabel fontWeight="bold" mb={1} htmlFor={name}>
        {label}
      </FormLabel>

      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <InputGroup alignItems="center" alignContent="center">
            {iconLeft && (
              <InputLeftElement pointerEvents="none" fontSize="1.2em">
                {iconLeft}
              </InputLeftElement>
            )}
            <Input
              id={name}
              placeholder={placeholder}
              mask={mask}
              as={!!mask && (InputMask as any)}
              background="theme.light.bodyInput"
              focusBorderColor={errors[name] ? '#B22222' : '#00FF00'}
              pl="60px"
              height={65}
              {...field}
              {...props}
            />
            {iconRight && (
              <InputRightElement pointerEvents="none" fontSize="1.2em">
                {iconRight}
              </InputRightElement>
            )}
          </InputGroup>
        )}
      />

      {errors[name] && <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>}
    </FormControl>
  );
};
