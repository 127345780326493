/* eslint-disable react-hooks/exhaustive-deps */

import { useToast } from '@chakra-ui/react';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { useState } from 'react';
import { useAuth } from '../providers/auth';
import { api } from '../services/api';

export interface ParamsType {
  // page: number;
  // per_page: number;
  search?: string | null;
  params?: object;
}

export interface ImageTypes {
  original: string;
  small: string;
  medium: string;
  large: string;
}

export interface PaginationType {
  has_next: boolean;
  has_prev: boolean;
  current_page: number;
  next_page: number;
  items: number;
  total_items: number;
  total_pages: number;
}

export interface DataType {
  pagination: PaginationType;
  status: string;
  msg: string;
  total: number | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

export interface ErrorType {
  status: string;
  msg: string;
}

export const useFetch = (url: string) => {
  const toast = useToast();
  const { Logout } = useAuth();
  const [data, setData] = useState<DataType | any | null>(null);
  const [error, setError] = useState<ErrorType | string | null | any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const requestApi = async (
    method: 'get' | 'post' | 'put' | 'delete',
    config?: AxiosRequestConfig | ParamsType | any,
    updateUrl?: string | any | null,
  ) => {
    setLoading(true);
    setError(null);
    try {
      let response;
      if (updateUrl) {
        response = await api[method](`${updateUrl}`, config);
      } else {
        response = await api[method](`${url}`, config);
      }
      setData(response.data);
      setLoading(false);

      if (method !== 'get') {
        return toast({
          title: 'Sucesso!',
          description: 'Ação concluída.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
      return data;
    } catch (e) {
      const err = e as AxiosError | ErrorType | any;

      if (err.response?.status === 422) {
        Logout();
      }

      setError(err.response?.data);
      setLoading(false);
      return toast({
        title: 'Algo deu errado!',
        description:
          err?.response?.data?.msg ?? 'Tente novamente ou entre em contato com o suporte.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return { requestApi, data, error, loading };
};
