/* eslint-disable prettier/prettier */
/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Flex, useMediaQuery } from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputHookForm } from '../../inputs/InputHookForm';
import { FormButton } from '../../buttons/FormButton';
import { useFetch } from '../../../hooks/useFetch';
import { SelectInputForm } from '../../inputs/SelectInputForm';

interface DecisionFormProps {
  defaultValues?: any;
  onClose: any;
  itemIdUrl: string;
}

interface FormInputsProps {
  name: string;
  value: string | any[];
  placeholder?: string;
  label: string | undefined;
  yup: any;
  mask?: string;
}

export default function SectorsForm({ defaultValues = {}, onClose, itemIdUrl }: DecisionFormProps) {
  const [isSmallerThan1280] = useMediaQuery('(max-width: 767px)');
  const yupschema: any = {};
  const defaultValuesSchema: any = {};
  const { requestApi: reqApiUnits, data: dataUnits } = useFetch('v1/admin/units');
  const { requestApi, data, loading } = useFetch('v1/admin/sectors');
  const { requestApi: reqApiClient, data: dataClient } = useFetch('v1/admin/clients');

  const formInputs: FormInputsProps[] = [
    {
      name: 'client',
      value: defaultValues.unit?.client?.id?.toString() ?? '',
      placeholder: 'Cliente',
      label: undefined,
      mask: '',
      yup: Yup.string().required('Este campo é obrigatório!'),
    },
    {
      name: 'responsible_name',
      value: defaultValues.responsible_name ?? '',
      placeholder: 'Nome do responsável',
      label: undefined,
      mask: '',
      yup: Yup.string().required('Este campo é obrigatório!'),
    },
    {
      name: 'unit_id',
      value: defaultValues?.unit_id?.toString() ?? '',
      placeholder: 'Unidade',
      label: undefined,
      mask: '',
      yup: Yup.string().required('Este campo é obrigatório!'),
    },
    {
      name: 'description',
      value: defaultValues.description ?? '',
      placeholder: 'Descrição do setor',
      label: undefined,
      mask: '',
      yup: Yup.string().required('Este campo é obrigatório!'),
    },
  ];

  const client = formInputs.find(field => field.name === 'client');
  const responsible_name = formInputs.find(field => field.name === 'responsible_name');
  const unit_id = formInputs.find(field => field.name === 'unit_id');
  const sectorDescription = formInputs.find(field => field.name === 'description');

  formInputs.forEach(object => {
    yupschema[object.name] = object.yup;
    defaultValuesSchema[object.name] = object.value;
  });
  const schema = Yup.object().shape(yupschema);

  const {
    register,
    reset,
    control,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValuesSchema,
  });

  const onSubmit = async (values: any) => {
    requestApi(
      defaultValues.description ? 'put' : 'post',
      values,
      defaultValues.description ? itemIdUrl : null,
    );
  };

  useEffect(() => {
    if (data?.msg === 'Registered successfully' || data?.msg === 'Updated successfully') {
      onClose();
    }
  }, [data?.msg]);

  useEffect(() => {
    if (defaultValues.description) {
      reset(defaultValuesSchema);
      setTimeout(() => {
        reset(defaultValuesSchema);
      }, 10);
    }
  }, [defaultValues]);

  useEffect(() => {
    if (data?.msg === 'Registered successfully') {
      onClose();
    }
  }, [data?.msg]);

  useEffect(() => {
    reqApiClient('get', {
      params: { page: 1, per_page: 100 },
    });
    reqApiUnits('get', {
      params: { page: 1, per_page: 100 },
    });
  }, []);

  return (
    <Box h="100%" pr="41%" overflowY="auto">
      <form onSubmit={handleSubmit(onSubmit)}>
        <SelectInputForm
          name={client?.name}
          label={client?.label}
          options={[
            ...(dataClient?.data?.map(item => ({
              label: item?.name,
              value: item?.id?.toString(),
            })) ?? []),
          ]}
          placeholder={client?.placeholder}
          control={control}
          errors={errors}
        />

        <SelectInputForm
          name={unit_id?.name}
          label={unit_id?.label}
          options={[
            ...(dataUnits?.data
              ?.filter(un => un.client.id.toString() === watch('client'))
              ?.map(item => ({
                label: item?.name,
                value: item?.id?.toString(),
              })) ?? []),
          ]}
          placeholder={unit_id?.placeholder}
          control={control}
          errors={errors}
        />
        <InputHookForm
          name={sectorDescription?.name}
          label={sectorDescription?.label}
          placeholder={sectorDescription?.placeholder}
          register={register}
          errors={errors}
        />
        <InputHookForm
          name={responsible_name?.name}
          label={responsible_name?.label}
          placeholder={responsible_name?.placeholder}
          register={register}
          errors={errors}
        />

        <Flex justify="space-between" mb={30}>
          <FormButton
            mt={4}
            variant="outline"
            isLoading={isSubmitting || loading}
            onClick={onClose}
            w={isSmallerThan1280 ? 280 : 250}
          >
            Fechar
          </FormButton>
          <FormButton
            mt={4}
            isLoading={isSubmitting || loading}
            type="submit"
            w={isSmallerThan1280 ? 280 : 250}
          >
            Salvar
          </FormButton>
        </Flex>
      </form>
    </Box>
  );
}
