import React from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, Image, useMediaQuery } from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../providers/auth';
import { InputHookForm } from '../../inputs/InputHookForm';
import emailIcon from '../../../assets/images/email-icon.png';
import keyIcon from '../../../assets/images/key-icon.png';
import { FormButton } from '../../buttons/FormButton';

const FormInputs = [
  {
    name: 'username',
    value: process.env.NODE_ENV === 'development' ? 'adminuser@thinkidea.app' : '',
    placeholder: 'Digite seu e-mail',
    type: 'text',
    label: '',
    yup: Yup.string().email('Digite um e-mail válido.').required('E-mail obrigatório.'),
    icon: <Image src={emailIcon} />,
  },
  {
    name: 'password',
    value: process.env.NODE_ENV === 'development' ? '123456' : '',
    label: '',
    type: 'password',
    placeholder: 'Digite sua senha',
    yup: Yup.string()
      .required('Senha obrigatória.')
      .min(6, 'Senha muito pequena.')
      .max(30, 'Senha muito pequena.'),
    icon: <Image src={keyIcon} />,
  },
];

export default function LoginForm({ onChange }: { onChange?: () => void }) {
  const navigate = useNavigate();
  const yupschema: any = {};
  const defaultValuesSchema: any = {};

  FormInputs.map(object => {
    yupschema[object.name] = object.yup;
    defaultValuesSchema[object.name] = object.value;
    return true;
  });
  const schema = Yup.object().shape(yupschema);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValuesSchema,
  });

  const { Login } = useAuth();

  const onSubmit = async (values: any) => {
    // eslint-disable-next-line no-alert
    const response = await Login(values);
    if (response?.data?.password_update) {
      navigate('/recover', { replace: true });
    }
  };

  const [isSmallerThan1280] = useMediaQuery('(max-width: 767px)');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {FormInputs.map(i => (
        <InputHookForm
          key={i.name}
          name={i.name}
          label={i.label}
          colorPlaceholder="#FFFFFF"
          placeholder={i.placeholder}
          icon={i.icon}
          register={register}
          errors={errors}
          type={i.type}
        />
      ))}
      <Box display="flex" flexDirection="row" justifyContent="flex-end" mt="28px">
        {/* <Link href="https://www.youtube.com" color="#00CEFF" textDecoration="underline">
          Criar conta
        </Link> */}
        <Button
          onClick={onChange}
          paddingTop={0}
          _hover={{ backgroundColor: 'transparent' }}
          padding={0}
          bgColor="transparent"
          color="#00CEFF"
          textDecoration="underline"
        >
          Esqueceu sua senha?
        </Button>
      </Box>
      <FormButton isLoading={isSubmitting} type="submit" w={isSmallerThan1280 ? 280 : 315}>
        Entrar
      </FormButton>
    </form>
  );
}
