/* eslint-disable react/no-children-prop */
import { IconButton, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

export const SaveButton = ({ onClick, ...rest }: any) => {
  return (
    <IconButton
      onClick={onClick}
      color={useColorModeValue('theme.light.secondary', 'theme.dark.secondary')}
      icon={
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.46179 1.39679C5.64296 1.21562 5.86165 1.07826 6.1 0.994862V0.9H6.2H20.2C20.6977 0.9 21.1 1.30227 21.1 1.8V20.2C21.1 20.6977 20.6977 21.1 20.2 21.1H1.8C1.30227 21.1 0.9 20.6977 0.9 20.2V6.6625C0.9 6.21104 1.07799 5.78059 1.39679 5.46179L5.46179 1.39679ZM14.1 2.9H7.9V5.3H14.1V2.9ZM19.1 19.1V2.9H15.9V6.2C15.9 6.69773 15.4977 7.1 15 7.1H7C6.50227 7.1 6.1 6.69773 6.1 6.2V3.58642L2.9 6.78642V19.1H19.1ZM11 9.15C13.0427 9.15 14.7 10.8073 14.7 12.85C14.7 14.8927 13.0427 16.55 11 16.55C8.95727 16.55 7.3 14.8927 7.3 12.85C7.3 10.8073 8.95727 9.15 11 9.15ZM11 14.75C12.0498 14.75 12.9 13.8998 12.9 12.85C12.9 11.8002 12.0498 10.95 11 10.95C9.95023 10.95 9.1 11.8002 9.1 12.85C9.1 13.8998 9.95023 14.75 11 14.75Z"
            fill="url(#paint0_linear_616_116)"
            stroke="url(#paint1_linear_616_116)"
            strokeWidth="0.2"
          />
          <defs>
            <linearGradient
              id="paint0_linear_616_116"
              x1="0.499999"
              y1="12.5"
              x2="21.5"
              y2="12.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00CEFF" />
              <stop offset="1" stopColor="#9747FF" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_616_116"
              x1="0.499999"
              y1="12.5"
              x2="21.5"
              y2="12.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00CEFF" />
              <stop offset="1" stopColor="#9747FF" />
            </linearGradient>
          </defs>
        </svg>
      }
      size="sm"
      mr={2}
      variant="ghost"
      {...rest}
      aria-label=""
    />
  );
};
