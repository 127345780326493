import { extendTheme, theme as base } from '@chakra-ui/react';
import { colors } from './colors';

export const theme = extendTheme({
  components: {
    Select: {
      // 1. We can update the base styles
      baseStyle: {
        fontWeight: 'bold', // Normally, it is "semibold"
      },
      Option: { color: 'black' },
      variants: {
        'with-dark': {
          field: {
            padding: 0,
            backgroundColor: 'transparent',
            color: 'white',
          },
        },
      },
    },
  },
  colors,
  fonts: {
    heading: `Roobert-Bold, ${base.fonts?.heading}`,
    body: `Roobert-Regular, ${base.fonts?.body}`,
  },
});
