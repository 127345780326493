import { STORAGE_KEYS } from '../constants/Config';

export const getAuthStorage = async (storageKey: string | undefined = undefined) => {
  try {
    const jsonValue = await localStorage.getItem(storageKey ?? STORAGE_KEYS.AUTH);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    return false;
  }
};

export const setAuthStorage = async (value: any, storageKey: string | undefined = undefined) => {
  try {
    const jsonValue = JSON.stringify(value);
    await localStorage.setItem(storageKey ?? STORAGE_KEYS.AUTH, jsonValue);
    return true;
  } catch (e) {
    return false;
  }
};

export const removeAuthStorage = async () => {
  try {
    await localStorage.removeItem(STORAGE_KEYS.AUTH);
    await localStorage.removeItem(STORAGE_KEYS.UNIT);
    await localStorage.removeItem(STORAGE_KEYS.CLIENT);
    return true;
  } catch (e) {
    return false;
  }
};
