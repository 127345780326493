import React from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, Image, useMediaQuery } from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputHookForm } from '../../inputs/InputHookForm';
import emailIcon from '../../../assets/images/email-icon.png';
import { useFetch } from '../../../hooks/useFetch';

const FormInputs = [
  {
    name: 'email',
    value: 'adminuser@thinkidea.app',
    placeholder: 'Digite seu e-mail',
    type: 'text',
    label: '',
    yup: Yup.string().email('Digite um e-mail válido').required('Campo obrigatório.'),
    icon: <Image src={emailIcon} />,
  },
];

export default function ResetForm({ onChange }: { onChange?: () => void }) {
  const yupschema: any = {};
  const defaultValuesSchema: any = {};

  FormInputs.map(object => {
    yupschema[object.name] = object.yup;
    defaultValuesSchema[object.name] = object.value;
    return true;
  });
  const schema = Yup.object().shape(yupschema);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValuesSchema,
  });

  const { requestApi } = useFetch('v1/admin/users/recover');

  const onSubmit = async (values: any) => {
    requestApi('post', values);
  };

  const [isSmallerThan1280] = useMediaQuery('(max-width: 767px)');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {FormInputs.map(i => (
        <InputHookForm
          key={i.name}
          name={i.name}
          label={i.label}
          colorPlaceholder="#FFFFFF"
          placeholder={i.placeholder}
          icon={i.icon}
          register={register}
          errors={errors}
        />
      ))}
      <Box display="flex" flexDirection="row" justifyContent="space-between" mt="28px">
        <Button
          onClick={onChange}
          paddingTop={0}
          _hover={{ backgroundColor: 'transparent' }}
          padding={0}
          bgColor="transparent"
          color="#00CEFF"
          textDecoration="underline"
        >
          Voltar
        </Button>
        <Button
          colorScheme="teal"
          isLoading={isSubmitting}
          type="submit"
          w={isSmallerThan1280 ? 180 : 250}
          h={46}
          borderRadius={12}
          fontFamily="Roobert-Regular"
          bgColor="#00B2DD"
        >
          Enviar
        </Button>
      </Box>
    </form>
  );
}
