import React from 'react';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Switch,
  Text,
  // useColorModeValue,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';

interface InputProps {
  name: string | undefined | any;
  label: string | undefined;
  errors: any;
  control: any;
}

export const SwitchHookForm = ({ name, label, errors, control }: InputProps) => {
  return (
    <FormControl isInvalid={!!errors[name]}>
      <FormLabel fontWeight="bold" htmlFor={name}>
        {label}
      </FormLabel>
      <Flex align="center" mb={2}>
        <Text>Ativo?</Text>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Switch
              size="lg"
              ml={3}
              colorScheme="yellow"
              onChange={e => field.onChange(e.target.checked)}
              isChecked={field.value}
            />
          )}
        />
      </Flex>
      {errors[name] && <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>}
    </FormControl>
  );
};
