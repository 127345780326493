import React, { useEffect } from 'react';
import ProfileForm from '../../components/forms/ProfileForm';
import { useFetch } from '../../hooks/useFetch';

const ProfilePage = () => {
  const baseUrl = `/v1/client`;

  const { requestApi, data } = useFetch(baseUrl);
  useEffect(() => {
    requestApi('get');
  }, []);

  return <ProfileForm defaultValues={data?.data} />;
};

export default ProfilePage;
