import NotFoundPage from '../pages/404';
import LoginPage from '../pages/auth/login';

export const OpenRoutes = [
  {
    name: 'Not Found',
    path: '*',
    component: NotFoundPage,
  },
  {
    name: 'Login',
    path: '/login',
    component: LoginPage,
  },
];
