/* eslint-disable no-unused-vars */
import React from 'react';
import { Select } from '@chakra-ui/react';
import { alphabeticalOrder } from '../../utils/OrderFunctions';

const DarkSelect = ({ value, data, onChange, alphabetical }: any) => {
  let selectData = data?.data;
  if (alphabetical) {
    selectData = selectData?.sort(alphabeticalOrder);
  }
  return (
    <Select
      variant="unstyled"
      color="white"
      placeholder="Selecione uma marca"
      value={value}
      onChange={e => {
        console.log(e);
        onChange(e);
      }}
    >
      {selectData?.map(itemClient => (
        <option key={itemClient.id} style={{ color: 'black' }} value={itemClient.id}>
          {itemClient.name}
        </option>
      ))}
    </Select>
  );
};

export default DarkSelect;
