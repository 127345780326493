/* eslint-disable prettier/prettier */
import { Box, Heading, Text, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { useLocation } from 'react-router-dom';
import DecisionForm from '../../components/forms/DecisionForm';
import ModalForm from '../../components/ModalForm';
import { STORAGE_KEYS } from '../../constants/Config';
import { useFetch } from '../../hooks/useFetch';
import { getAuthStorage } from '../../services/storage';

export default function DashboardPage() {
  const { pathname } = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [unitId, setUnitId] = useState<number | string>('');
  const [link, setLink] = useState<string>('');
  const [title, setTitle] = useState<string>('');

  const { data, requestApi } = useFetch(`/v1/admin/units/${unitId}`);

  const getUnit = async () => {
    const unitStorage = await getAuthStorage(STORAGE_KEYS.UNIT);
    setUnitId(unitStorage);
  };

  useEffect(() => {
    if (pathname === '/numeros') {
      setLink(data?.data.link_numbers);
      setTitle('Números');
    } else {
      setLink(data?.data.link_analysis);
      setTitle('Análises');
    }
  }, [data?.data]);
  useEffect(() => {
    getUnit();
  }, []);
  useEffect(() => {
    if (unitId) {
      requestApi('get');
    }
  }, [unitId]);
  return (
    <Box position="relative" display="flex" height="100%" flexDirection="column">
      <Heading as="h1" size="lg" mb={9} mt={7}>
        {title}
      </Heading>

      <iframe
        title="powerbi"
        style={{ width: '100%', overflow: 'visible', display: 'flex', flex: 1 }}
        src={link || ''}
      />
      <Box position="absolute" bottom={0} h={35} w="100%" bg="white" zIndex={9}>
        <Text>Método NADE</Text>
      </Box>
      <Draggable bounds="body">
        <Box w="200px" position="fixed" top={10} right={16} zIndex={10}>
          <ModalForm
            isOpen={isOpen}
            onClose={onClose}
            RenderedForm={DecisionForm}
            onClick={onOpen}
            description="decisão"
            w="200px"
          />
        </Box>
      </Draggable>
    </Box>
  );
}
