/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Flex, useMediaQuery } from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { InputHookForm } from '../../inputs/InputHookForm';
import { FormButton } from '../../buttons/FormButton';
import { useFetch } from '../../../hooks/useFetch';

interface FormInputsProps {
  name: string;
  value: string;
  placeholder?: string;
  label: string | undefined;
  yup: any;
}

export default function NewPassForm() {
  const [isSmallerThan1280] = useMediaQuery('(max-width: 767px)');
  const yupschema: any = {};
  const defaultValuesSchema: any = {};
  const baseUrl = '/v1/client';
  const { requestApi, data, loading } = useFetch(baseUrl);
  const navigate = useNavigate();

  const formInputs: FormInputsProps[] = [
    {
      name: 'password',
      value: '',
      placeholder: 'Nova senha',
      label: '',
      yup: Yup.string()
        .required('Este campo é obrigatório!')
        .min(6, 'Senha muito curta')
        .max(30, 'Senha muito longa'),
    },
    {
      name: 'confirm_password',
      value: '',
      placeholder: 'Confirme a nova senha',
      label: '',
      yup: Yup.string()
        .required('Este campo é obrigatório!')
        .oneOf([Yup.ref('password'), null], 'As senhas não são iguais'),
    },
  ];

  formInputs.forEach(object => {
    yupschema[object.name] = object.yup;
    defaultValuesSchema[object.name] = object.value;
  });
  const schema = Yup.object().shape(yupschema);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValuesSchema,
  });

  const password = formInputs.filter(field => field.name === 'password').shift();
  const confirmPassword = formInputs.filter(field => field.name === 'confirm_password').shift();

  const onSubmit = async (values: any) => {
    console.log({
      ...data?.data,
      ...values,
      confirm_password: _,
      clients: _,
      units: _,
      created_at: _,
      updated_at: _,
      group: _,
      hash_id: _,
      id: _,
    });
    await requestApi('put', {
      ...data?.data,
      ...values,
      group_id: data?.data?.group?.id,
      confirm_password: _,
      clients: _,
      units: _,
      created_at: _,
      updated_at: _,
      group: _,
      hash_id: _,
      id: _,
    });

    navigate('/', { replace: true });
  };

  useEffect(() => {
    requestApi('get');
  }, []);

  return (
    <Box h="100%" maxWidth="530px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex direction="column" justify="space-evenly">
          <InputHookForm
            name={password?.name}
            label={password?.label}
            placeholder={password?.placeholder}
            register={register}
            errors={errors}
            type="password"
          />

          <InputHookForm
            name={confirmPassword?.name}
            label={confirmPassword?.label}
            placeholder={confirmPassword?.placeholder}
            register={register}
            errors={errors}
            type="password"
          />

          <FormButton
            mt={4}
            isLoading={isSubmitting || loading}
            type="submit"
            w={isSmallerThan1280 ? 280 : 250}
          >
            Salvar nova senha
          </FormButton>
        </Flex>
      </form>
    </Box>
  );
}
