/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Flex, useMediaQuery } from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputHookForm } from '../../inputs/InputHookForm';
import { FormButton } from '../../buttons/FormButton';
import { useFetch } from '../../../hooks/useFetch';

interface ClientFormProps {
  defaultValues?: any;
  onClose: any;
  itemIdUrl?: string;
}

interface FormInputsProps {
  name: string;
  value: string;
  placeholder?: string;
  label: string | undefined;
  yup: any;
}

export default function ClientsForm({ defaultValues = {}, onClose, itemIdUrl }: ClientFormProps) {
  const [isSmallerThan1280] = useMediaQuery('(max-width: 767px)');
  const yupschema: any = {};
  const defaultValuesSchema: any = {};
  const { requestApi, data, loading } = useFetch('v1/admin/clients');

  useEffect(() => {
    if (data?.msg === 'Registered successfully') {
      onClose();
    }
  }, [data?.msg]);

  const formInputs: FormInputsProps[] = [
    {
      name: 'name',
      value: defaultValues.name ?? '',
      placeholder: 'Nome do fantasia',
      label: undefined,
      yup: Yup.string().required('Este campo é obrigatório!'),
    },
    {
      name: 'company_name',
      value: defaultValues.company_name ?? '',
      placeholder: 'Razão social',
      label: undefined,
      yup: Yup.string().required('Este campo é obrigatório!'),
    },
    {
      name: 'contact_name',
      value: defaultValues.contact_name ?? '',
      placeholder: 'Nome do contato',
      label: undefined,
      yup: Yup.string().required('Este campo é obrigatório!'),
    },
    {
      name: 'phone',
      value: defaultValues.phone ?? '',
      placeholder: 'Telefone',
      label: undefined,
      yup: Yup.string().required('Este campo é obrigatório!'),
    },
    {
      name: 'comments',
      value: defaultValues.comments ?? '',
      placeholder: 'Comentários',
      label: undefined,
      yup: Yup.string(),
    },
  ];

  formInputs.forEach(object => {
    yupschema[object.name] = object.yup;
    defaultValuesSchema[object.name] = object.value;
  });
  const schema = Yup.object().shape(yupschema);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValuesSchema,
  });

  useEffect(() => {
    if (defaultValues.name) {
      reset(defaultValuesSchema);
      setTimeout(() => {
        reset(defaultValuesSchema);
      }, 10);
    }
  }, [defaultValues]);

  const name = formInputs.filter(field => field.name === 'name').shift();
  const company_name = formInputs.filter(field => field.name === 'company_name').shift();
  const contact_name = formInputs.filter(field => field.name === 'contact_name').shift();
  const phone = formInputs.filter(field => field.name === 'phone').shift();
  const comments = formInputs.filter(field => field.name === 'comments').shift();

  const onSubmit = async (values: any) => {
    requestApi(defaultValues.name ? 'put' : 'post', values, defaultValues.name ? itemIdUrl : null);
  };

  useEffect(() => {
    if (data?.msg === 'Registered successfully' || data?.msg === 'Updated successfully') {
      onClose();
    }
  }, [data?.msg]);

  return (
    <Box h="100%" maxWidth="530px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex direction="column" justify="space-evenly">
          <InputHookForm
            name={name?.name}
            label={name?.label}
            placeholder={name?.placeholder}
            register={register}
            errors={errors}
          />
          <InputHookForm
            name={company_name?.name}
            label={company_name?.label}
            placeholder={company_name?.placeholder}
            register={register}
            errors={errors}
          />

          <InputHookForm
            name={contact_name?.name}
            label={contact_name?.label}
            placeholder={contact_name?.placeholder}
            register={register}
            errors={errors}
          />

          <InputHookForm
            name={phone?.name}
            label={phone?.label}
            placeholder={phone?.placeholder}
            register={register}
            errors={errors}
            mask="(99) 99999-9999"
          />
          <InputHookForm
            name={comments?.name}
            label={comments?.label}
            placeholder={comments?.placeholder}
            register={register}
            errors={errors}
          />
          <Flex justify="space-between" mb={12}>
            <FormButton
              mt={4}
              variant="outline"
              isLoading={isSubmitting || loading}
              onClick={onClose}
              w={isSmallerThan1280 ? 280 : 250}
            >
              Fechar
            </FormButton>
            <FormButton
              mt={4}
              isLoading={isSubmitting || loading}
              type="submit"
              w={isSmallerThan1280 ? 280 : 250}
            >
              Salvar cliente
            </FormButton>
          </Flex>
        </Flex>
      </form>
    </Box>
  );
}
