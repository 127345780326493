import * as React from 'react';
import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { theme } from './theme';

// locales
import './constants/locales/i18n';
import AppRouter from './routes';
import { AuthProvider } from './providers/auth';
import { SettingsProvider } from './providers/settings';

export function App() {
  return (
    <SettingsProvider>
      <ChakraProvider theme={theme}>
        <CSSReset />
        <BrowserRouter>
          <AuthProvider>
            <AppRouter />
          </AuthProvider>
        </BrowserRouter>
      </ChakraProvider>
    </SettingsProvider>
  );
}
