/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { DeleteButton } from '../buttons/DeleteButton';

const ConfirmModal = ({ handleDeleteItem, itemId }: any) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <DeleteButton onClick={onOpen} />

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent h="200px" justifyContent="space-between">
          <ModalCloseButton />

          <ModalHeader>Deseja mesmo excluir este item?</ModalHeader>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Fechar
            </Button>
            <Button onClick={() => handleDeleteItem(itemId)}>Confirmar exclusão</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConfirmModal;
