import React from 'react';

export default function EmptySvg() {
  return (
    <svg
      width="280"
      height="237"
      viewBox="0 0 280 237"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M204.789 167.557C204.789 167.557 162.148 170.784 159.789 166.71C157.433 162.639 183.789 161.427 175.218 159.674C166.647 157.924 22.264 154.709 45.4047 173.353C55.6606 181.616 50.763 181.531 34.4778 180.674C18.1927 179.818 -26.206 185.369 21.3626 196.396C68.9336 207.423 102.147 212.351 89.5029 215.994C76.8609 219.637 79.6452 240.214 119.074 234.961C158.502 229.708 274.429 212.148 269.927 207.964C265.427 203.78 230.07 203.42 249.355 197.493C268.64 191.566 235.641 183.208 222.784 183.852C209.927 184.495 204.784 167.554 204.784 167.554L204.789 167.557Z"
        fill="#EDEDED"
      />
      <path
        d="M126.983 215.33L146.969 236.676L197.212 228.801L197.167 227.089L174.136 209.679L126.938 213.615L126.983 215.33Z"
        fill="#8539CC"
      />
      <path
        d="M126.938 213.615L146.927 234.963L197.167 227.089L174.094 207.964L126.938 213.615Z"
        fill="#A643FF"
      />
      <path
        d="M126.938 213.615L126.595 200.335L173.748 194.684L193.614 214.311L193.271 227.7L146.927 234.963L126.938 213.615Z"
        fill="#EDCCD4"
      />
      <path
        d="M200.034 147.976L203.417 170.784L231.145 187.71L233.631 187.285L225.791 160.335L200.034 147.976Z"
        fill="#8914A2"
      />
      <path
        d="M200.93 173.353L159.789 174.64V176.783L191.288 198.64L234.061 189.21L233.631 187.285L200.93 173.353Z"
        fill="#8914A2"
      />
      <path d="M271.429 0V183.854L165.147 132.855V45.4277L271.429 0Z" fill="#DC85F0" />
      <path
        d="M271.429 0H275.285C275.285 0 280 51.8552 280 91.9272C280 131.999 274.572 182.71 274.572 182.71L271.429 183.852V0Z"
        fill="#8914A2"
      />
      <path d="M271.429 144.426V183.854L165.147 132.855V117.855L271.429 144.426Z" fill="#BB35D9" />
      <path d="M167.869 118.536V48.4274L263.715 14.57V142.498L167.869 118.536Z" fill="#EDCCD4" />
      <path
        d="M231.145 187.71L214.371 178.549C214.371 178.549 214.354 178.541 214.349 178.541H214.344V178.536L200.93 171.212L197.55 148.404L210.698 154.712V154.717L223.302 160.764L231.145 187.713V187.71Z"
        fill="#DC85F0"
      />
      <path
        d="M200.93 171.21L159.789 174.64L191.288 196.496L231.144 187.71L200.93 171.21Z"
        fill="#DC85F0"
      />
      <path
        d="M126.638 202.047L146.626 223.395L196.867 215.521L196.824 213.808L173.793 196.399L126.595 200.335L126.638 202.047Z"
        fill="#8539CC"
      />
      <path
        d="M126.595 200.335L146.581 221.68L196.824 213.808L173.748 194.684L126.595 200.335Z"
        fill="#A643FF"
      />
      <path d="M193.486 219.294L184.86 220.138L193.486 217.995V219.294Z" fill="#DC85F0" />
      <path
        d="M193.436 221.282L183.218 222.459L190.111 222.602L179.432 224.602L193.373 223.711L193.436 221.282Z"
        fill="#DC85F0"
      />
      <path
        d="M218.287 173.353C220.406 167.073 210.923 155.002 210.698 154.714L223.302 160.761L231.145 187.71L214.371 178.549C214.589 178.661 216.292 179.282 218.287 173.356V173.353Z"
        fill="#BB35D9"
      />
      <path
        d="M118.648 161.227C118.648 161.227 115.648 165.766 115.969 166.76C116.289 167.757 124.327 167.534 124.327 167.534L121.327 159.674L118.648 161.224V161.227Z"
        fill="#7424ED"
      />
      <path
        d="M131.718 162.001C131.718 162.001 129.147 166.803 129.79 167.557C130.434 168.31 136.541 168.13 136.861 167.557C137.182 166.983 134.398 161.004 134.398 161.004L131.718 162.001Z"
        fill="#7424ED"
      />
      <path
        d="M136.886 161.447C132.947 164.322 129.687 161.447 129.687 161.447C129.687 161.447 128.145 153.385 126.948 147.047C126.28 143.504 125.726 140.502 125.611 139.749C125.291 137.648 122.606 123.874 122.606 123.874L122.006 115.902L121.109 103.996L132.68 103.109C132.68 103.109 134.072 104.772 134.87 106.71C135.672 108.643 135.038 115.068 135.038 115.068C135.038 115.068 135.038 134.328 134.931 135.767C134.823 137.202 136.884 161.447 136.884 161.447H136.886Z"
        fill="#31B4D3"
      />
      <path
        d="M126.948 147.047C126.28 143.504 125.726 140.502 125.611 139.749C125.291 137.648 122.607 123.874 122.607 123.874L122.006 115.902C123.596 115.045 124.968 114.317 124.968 114.317C124.968 114.317 126.039 117.96 124.968 121.067C123.896 124.175 126.948 147.047 126.948 147.047Z"
        fill="#2B98AA"
      />
      <path
        d="M116.613 161.89C116.613 161.89 121.971 162.887 123.148 161.004C123.148 161.004 121.658 139.631 121.658 136.862C121.658 134.092 123.791 121.042 123.791 120.712C123.791 120.381 124.97 118.498 124.97 117.502C124.97 116.505 124.435 107.429 124.435 107.429C124.435 107.429 112.864 107.041 112.649 108.673C112.434 110.306 111.662 133.411 112.005 136.068C112.351 138.725 116.613 161.888 116.613 161.888V161.89Z"
        fill="#37D4FF"
      />
      <path
        d="M136.165 105.603C136.165 105.603 138.486 98.1192 138.862 96.5693C139.237 95.0194 135.354 88.8749 134.876 88.5695C134.397 88.264 133.058 76.2704 134.13 76.1052C135.201 75.9399 146.075 94.7014 145.863 96.5819C145.647 98.4648 137.988 110.003 137.988 110.003L136.168 105.603H136.165Z"
        fill="#31B4D3"
      />
      <path
        d="M135.502 106.545C134.973 106.888 124.63 108.092 117.692 108.52C114.246 108.736 111.645 108.756 111.502 108.388C111.194 107.594 109.955 91.0959 109.612 81.5461C109.471 77.8003 109.471 75.1262 109.719 74.8858C110.34 74.2823 118.483 74.5002 124.913 74.9409C131.34 75.3815 133.862 75.7171 134.127 76.1027C134.397 76.4883 136.29 106.024 135.502 106.545Z"
        fill="#37D4FF"
      />
      <path
        d="M119.632 107.689C119.632 107.689 118.836 108.032 117.692 108.52C114.246 108.736 111.645 108.756 111.502 108.388C111.194 107.594 109.955 91.0958 109.612 81.5461C110.303 79.4929 110.751 78.1433 110.751 78.1433C110.751 78.1433 110.576 89.5685 111.86 90.4999C113.145 91.4264 113.36 95.3549 112.216 96.354C111.071 97.358 112.919 106.707 119.63 107.689H119.632Z"
        fill="#31B4D3"
      />
      <path
        d="M77.0764 61.4576C77.0764 61.4576 83.291 60.3508 84.3627 60.6463C85.4343 60.9418 89.5056 65.296 89.6484 65.8869C89.7911 66.4778 86.7914 67.7147 86.6487 67.7598C86.506 67.8049 85.6497 66.0346 85.0763 66.0346C84.5029 66.0346 82.5774 66.1824 82.4347 65.962C82.292 65.7417 84.7207 65.0756 84.8635 64.4872C85.0062 63.8963 83.2209 63.0851 82.7201 62.717C82.2194 62.3489 77.0764 62.6444 77.0764 61.4626V61.4576Z"
        fill="#FCDFD0"
      />
      <path
        d="M85.2188 67.7573C85.2188 67.7573 89.7908 64.4071 90.2891 64.9229C90.7874 65.4387 91.5035 67.7548 91.5035 67.7548C91.5035 67.7548 87.0741 70.8296 86.5032 70.126C85.9324 69.4224 85.2188 67.7548 85.2188 67.7548V67.7573Z"
        fill="#37D4FF"
      />
      <path
        d="M86.9338 70.2336C86.9338 70.2336 94.6483 82.5627 96.7916 82.8581C98.9349 83.1536 109.694 83.5968 109.694 83.5968C109.694 83.5968 112.078 76.4382 109.719 74.8883C109.719 74.8883 98.7897 75.6996 98.4341 75.5518C98.0761 75.4041 91.5059 67.7573 91.5059 67.7573C91.5059 67.7573 88.0781 69.197 86.9338 70.2336Z"
        fill="#31B4D3"
      />
      <path
        d="M111.505 108.39C111.505 108.39 110.984 111.074 112.191 111.966L135.504 106.545C135.504 106.545 114.111 108.02 111.505 108.39Z"
        fill="#EDCCD4"
      />
      <path
        d="M132.362 107.389C132.362 107.389 132.414 112.742 134.773 113.46L136.889 110.804L135.507 106.542C135.507 106.542 134.077 106.627 132.364 107.389H132.362Z"
        fill="#FCDFD0"
      />
      <path
        d="M134.875 106.71C134.875 106.71 136.646 109.657 136.11 111.783C136.11 111.783 137.505 111.527 137.985 110.005C137.985 110.005 137.289 105.606 136.165 105.606C135.041 105.606 134.875 106.713 134.875 106.713V106.71Z"
        fill="#37D4FF"
      />
      <path
        d="M126.913 71.2276C126.913 71.2276 125.884 71.5356 125.884 72.7825V75.3916L119.369 75.0786L121.422 65.3686H125.584L126.112 67.6922L126.913 71.2276Z"
        fill="#FCDFD0"
      />
      <path
        d="M126.913 71.2276C126.913 71.2276 125.884 71.5356 125.884 72.7825V73.8016C125.075 73.9468 124.464 74.032 124.464 74.032C124.464 74.032 125.343 71.9187 124.464 71.2251C123.586 70.5316 122.604 67.9626 122.604 67.9626L126.109 67.6872L126.911 71.2226L126.913 71.2276Z"
        fill="#E8BAA6"
      />
      <path
        d="M118.225 74.8783C118.225 74.8783 118.052 71.4354 118.473 71.4354C118.893 71.4354 126.793 71.7459 126.913 72.3694C127.033 72.9929 126.913 75.359 126.913 75.359C126.913 75.359 123.35 77.1818 118.225 74.8783Z"
        fill="#E2B5C2"
      />
      <path
        d="M131.493 60.0153C131.493 60.0153 131.493 65.9946 130.226 67.9251C128.959 69.8555 127.754 71.2878 126.911 71.2252C126.067 71.1626 122.692 68.4834 122.148 67.3016C121.605 66.1173 119.677 64.4372 119.677 64.4372C119.677 64.4372 119.977 58.5205 120.401 58.4579C120.824 58.3953 130.589 55.5309 130.589 55.5309C130.589 55.5309 131.553 58.1374 131.493 60.0153Z"
        fill="#FCDFD0"
      />
      <path
        d="M125.118 61.4701C125.118 61.4701 129.435 61.6078 130.702 59.83C131.968 58.0523 130.914 57.1935 130.556 57.2486C130.198 57.3036 123.343 57.7318 123.145 57.9446C122.947 58.1575 125.115 61.4701 125.115 61.4701H125.118Z"
        fill="#E8BAA6"
      />
      <path
        d="M122.632 64.0615C122.632 64.0615 122.692 62.4415 121.668 62.5041C120.644 62.5667 121.004 66.4903 122.151 66.4903L120.751 68.556C120.751 68.556 115.819 60.4485 115.879 59.2641C115.939 58.0798 118.773 52.724 119.677 52.5988C120.581 52.4736 121.485 50.3554 121.968 50.4179C122.451 50.4805 122.209 51.6649 122.209 51.6649C122.209 51.6649 130.409 51.4771 132.156 53.5954C133.904 55.7136 131.368 62.8772 131.385 62.6318C131.403 62.3865 130.469 58.5155 130.106 58.4529C130.106 58.4529 126.926 61.6954 124.324 61.5051C122.632 61.3799 122.632 64.059 122.632 64.059V64.0615Z"
        fill="#090B33"
      />
      <path
        d="M121.415 63.378C121.415 63.378 122.434 63.5333 122.289 64.4972C122.289 64.4972 122.111 63.8112 121.415 63.378Z"
        fill="#E8BAA6"
      />
      <path
        d="M71.5052 101.262V165.854H65.0627V101.174L68.5782 99.2134L71.5052 101.262Z"
        fill="#ADADEA"
      />
      <path
        d="M68.5757 59.9502C57.0954 59.9502 47.791 69.2571 47.791 80.7348C47.791 92.2126 57.0979 101.519 68.5757 101.519C80.0534 101.519 89.3603 92.2126 89.3603 80.7348C89.3603 69.2571 80.0534 59.9502 68.5757 59.9502ZM68.5757 97.3781C59.3839 97.3781 51.9324 89.9266 51.9324 80.7348C51.9324 71.5431 59.3839 64.0916 68.5757 64.0916C77.7674 64.0916 85.2189 71.5431 85.2189 80.7348C85.2189 89.9266 77.7674 97.3781 68.5757 97.3781Z"
        fill="#8686E0"
      />
      <path
        opacity="0.42"
        d="M68.5756 97.3781C77.7674 97.3781 85.2188 89.9266 85.2188 80.7348C85.2188 71.543 77.7674 64.0916 68.5756 64.0916C59.3838 64.0916 51.9324 71.543 51.9324 80.7348C51.9324 89.9266 59.3838 97.3781 68.5756 97.3781Z"
        fill="#D8606E"
      />
      <path d="M71.5052 150.853H65.0627V154.709H71.5052V150.853Z" fill="#8686E0" />
      <path
        d="M78.7916 87.0797C78.2157 86.5939 78.088 85.7551 78.4961 85.1242C80.2062 82.4851 80.3064 79.513 78.7891 76.268C77.9928 74.5628 76.9312 73.1707 76.1825 72.3043C75.6843 71.7259 75.7043 70.8671 76.2251 70.3062L76.2376 70.2912C76.8435 69.6377 77.8801 69.6552 78.4686 70.3238C81.661 73.9394 85.0412 80.5771 81.005 86.7717C80.5192 87.5179 79.4751 87.6531 78.7916 87.0797Z"
        fill="#F2DDE3"
      />
      <path
        d="M51.6718 190.705C51.3288 191.203 51.121 191.496 51.121 191.496C51.121 191.496 51.1109 191.291 51.1134 190.935C51.1059 188.86 51.3788 181.696 55.4251 180.331C59.4463 178.979 53.5547 187.943 51.6718 190.705Z"
        fill="#D8606E"
      />
      <path
        d="M51.1838 190.81C51.1462 191.168 51.1187 191.414 51.1212 191.496C51.1212 191.496 50.9834 191.283 50.7631 190.915C49.3634 188.612 44.5635 180.274 48.3869 180.429C52.2504 180.589 51.4391 188.376 51.1813 190.81H51.1838Z"
        fill="#6158D6"
      />
      <path
        d="M51.1837 190.81C51.1462 191.168 51.1186 191.413 51.1211 191.496C51.1211 191.496 50.9834 191.283 50.7631 190.915L48.9377 183.043L51.1837 190.81Z"
        fill="#361CB7"
      />
      <path
        d="M54.9093 182.52L51.6718 190.705C51.3288 191.203 51.121 191.496 51.121 191.496C51.121 191.496 51.1109 191.291 51.1134 190.935L54.9093 182.52Z"
        fill="#CC485B"
      />
      <path
        d="M51.0837 191.07C51.0837 191.07 49.2583 191.055 49.9544 191.534C50.6505 192.009 52.939 191.759 52.3206 191.461C51.5769 191.103 51.0862 191.07 51.0862 191.07H51.0837Z"
        fill="#C1C1C1"
      />
      <path
        d="M104.388 219.532C104.045 220.03 103.838 220.323 103.838 220.323C103.838 220.323 103.827 220.118 103.83 219.762C103.822 217.687 104.095 210.523 108.142 209.158C112.163 207.806 106.271 216.77 104.388 219.532Z"
        fill="#D8606E"
      />
      <path
        d="M103.9 219.637C103.863 219.995 103.835 220.241 103.838 220.323C103.838 220.323 103.7 220.11 103.48 219.742C102.08 217.439 97.2801 209.101 101.103 209.256C104.967 209.416 104.156 217.203 103.898 219.637H103.9Z"
        fill="#6158D6"
      />
      <path
        d="M103.9 219.637C103.863 219.995 103.835 220.241 103.838 220.323C103.838 220.323 103.7 220.11 103.48 219.742L101.654 211.87L103.9 219.637Z"
        fill="#361CB7"
      />
      <path
        d="M107.626 211.347L104.388 219.532C104.045 220.03 103.838 220.323 103.838 220.323C103.838 220.323 103.827 220.118 103.83 219.762L107.626 211.347Z"
        fill="#CC485B"
      />
      <path
        d="M103.8 219.898C103.8 219.898 101.975 219.883 102.671 220.361C103.367 220.836 105.656 220.586 105.037 220.288C104.293 219.93 103.803 219.898 103.803 219.898H103.8Z"
        fill="#C1C1C1"
      />
      <path
        d="M240.957 209.148C240.614 209.647 240.406 209.94 240.406 209.94C240.406 209.94 240.396 209.734 240.399 209.379C240.391 207.303 240.664 200.139 244.711 198.775C248.732 197.423 242.84 206.387 240.957 209.148Z"
        fill="#D8606E"
      />
      <path
        d="M240.469 209.256C240.431 209.614 240.404 209.86 240.406 209.942C240.406 209.942 240.269 209.729 240.048 209.361C238.649 207.058 233.849 198.72 237.672 198.875C241.536 199.035 240.724 206.822 240.466 209.256H240.469Z"
        fill="#6158D6"
      />
      <path
        d="M240.469 209.256C240.431 209.614 240.404 209.86 240.406 209.942C240.406 209.942 240.269 209.729 240.048 209.361L238.223 201.489L240.469 209.256Z"
        fill="#361CB7"
      />
      <path
        d="M244.195 200.966L240.957 209.151C240.614 209.649 240.406 209.942 240.406 209.942C240.406 209.942 240.396 209.737 240.399 209.381L244.195 200.966Z"
        fill="#CC485B"
      />
      <path
        d="M240.369 209.514C240.369 209.514 238.543 209.499 239.24 209.977C239.936 210.453 242.224 210.203 241.606 209.905C240.862 209.547 240.371 209.514 240.371 209.514H240.369Z"
        fill="#C1C1C1"
      />
      <path
        d="M218.6 105.38L193.158 103.009C192.51 102.949 192.019 102.343 192.052 101.642C192.084 100.941 192.63 100.4 193.281 100.423L218.793 101.327C219.469 101.352 219.997 101.97 219.962 102.699L219.892 104.166C219.857 104.895 219.274 105.443 218.598 105.38H218.6Z"
        fill="#7424ED"
      />
      <path
        d="M180.896 68.118L180.893 68.1189C179.832 68.5372 179.311 69.7367 179.729 70.7981L187.254 89.8929C187.673 90.9543 188.872 91.4756 189.934 91.0573L189.936 91.0564C190.997 90.6381 191.519 89.4386 191.1 88.3772L183.575 69.2824C183.157 68.221 181.957 67.6997 180.896 68.118Z"
        fill="#BB35D9"
      />
      <path
        d="M188.281 68.4022L178.884 86.6547C178.362 87.669 178.761 88.9146 179.776 89.4368L179.778 89.4379C180.792 89.9601 182.038 89.5612 182.56 88.5468L191.957 70.2943C192.479 69.28 192.08 68.0344 191.065 67.5122L191.063 67.5111C190.049 66.9889 188.803 67.3879 188.281 68.4022Z"
        fill="#7424ED"
      />
      <path
        d="M223.241 62.6585L223.239 62.6597C222.214 63.1616 221.791 64.399 222.293 65.4235L236.023 93.4516C236.525 94.4761 237.763 94.8998 238.787 94.3979L238.789 94.3968C239.814 93.8949 240.238 92.6575 239.736 91.6329L226.005 63.6048C225.503 62.5803 224.266 62.1566 223.241 62.6585Z"
        fill="#7424ED"
      />
      <path
        d="M222.614 92.5056C221.597 91.9497 221.194 90.6151 221.715 89.526L234.94 61.8031C235.458 60.7139 236.705 60.2832 237.719 60.8391C238.736 61.395 239.139 62.7295 238.618 63.8187L225.393 91.5416C224.875 92.6308 223.628 93.0614 222.614 92.5056Z"
        fill="#BB35D9"
      />
    </svg>
  );
}
