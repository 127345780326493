/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-children-prop */
import React from 'react';
import { Input } from '@chakra-ui/react';
import { css } from '@emotion/react';

export const DateFilter = ({ onChange, placeholder }: any) => {
  return (
    <Input
      placeholder={placeholder ?? 'Selecionar data'}
      borderRadius="full"
      boxShadow="0px 3px 6px #00000029"
      variant="outline"
      onChange={e => onChange(e.target.value)}
      onFocus={e => (e.target.type = 'date')}
      onBlur={e => (e.target.value ? (e.target.type = 'date') : (e.target.type = 'text'))}
      type="text"
      css={css`
        ::-webkit-calendar-picker-indicator {
          background: url(https://cdn3.iconfinder.com/data/icons/linecons-free-vector-icons-pack/32/calendar-16.png)
            center/80% no-repeat;
          position: absolute;
          left: 5px;
          color: black;
          opacity: 1;
          display: block;
          width: 20px;
          height: 20px;
          border-width: thin;
          cursor: pointer;
        }
        ::-webkit-datetime-edit {
          position: relative;
          left: 6px;
        }
        ::-webkit-datetime-edit-fields-wrapper {
          position: relative;
          left: 15px;
        }
      `}
    />
  );
};
