/* eslint-disable no-unused-vars */
/* eslint-disable react/no-children-prop */
import React from 'react';
import { Input, InputGroup, InputLeftElement, Select } from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';

export const SelectFilter = ({ onChange, options, placeholder }: any) => {
  return (
    <InputGroup>
      {/* <InputLeftElement pointerEvents="none" children={<FaSearch color="gray.300" />} /> */}
      <Select
        placeholder={placeholder}
        borderRadius="full"
        boxShadow="0px 3px 6px #00000029"
        variant="outline"
        onChange={e => onChange(e.target.value)}
      >
        {options?.map(item => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </Select>
    </InputGroup>
  );
};
