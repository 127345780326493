/* eslint-disable react/no-children-prop */
import { IconButton, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

export const DeleteButton = ({ onClick }: any) => {
  return (
    <IconButton
      onClick={onClick}
      color={useColorModeValue('theme.light.secondary', 'theme.dark.secondary')}
      icon={
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 4.8H20M8.6 9.55V14.3M12.4 9.55V14.3M2.9 4.8H18.1L16.599 18.309C16.5475 18.7739 16.3264 19.2035 15.9779 19.5155C15.6295 19.8275 15.1781 20 14.7104 20H6.2896C5.82185 20 5.37053 19.8275 5.02206 19.5155C4.67359 19.2035 4.45245 18.7739 4.401 18.309L2.9 4.8ZM6.07775 2.08965C6.23141 1.76377 6.47457 1.4883 6.77885 1.29537C7.08314 1.10243 7.43601 0.999996 7.7963 1H13.2037C13.5642 0.999816 13.9172 1.10217 14.2217 1.29511C14.5262 1.48805 14.7695 1.76362 14.9232 2.08965L16.2 4.8H4.8L6.07775 2.08965Z"
            stroke="url(#paint0_linear_616_123)"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_616_123"
              x1="20.475"
              y1="11.925"
              x2="0.525001"
              y2="11.925"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00CEFF" />
              <stop offset="1" stopColor="#9747FF" />
            </linearGradient>
          </defs>
        </svg>
      }
      size="sm"
      mr={2}
      variant="ghost"
      aria-label=""
    />
  );
};
