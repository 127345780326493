/* eslint-disable consistent-return */
/* eslint-disable prettier/prettier */
/* eslint-disable camelcase */
import { Box } from '@chakra-ui/react';
import React from 'react';
import IndicatorsForm from '../../../components/forms/IndicatorsForm';

/* const schema = Yup.object().shape({
  link: Yup.string().required('Este campo é obrigatório!'),
  name: Yup.string().required('Este campo é obrigatório!'),
}); */
export default function IndicatorsPage() {
  return (
    <Box position="relative">
      {/*   <TabNavigator routes={['DHO', 'Indicador 2']} />
      <Box display="flex">
        {[
          { name: 'year', value: 'year', type: 'number', label: 'Informe o ano' },
          { name: 'year', value: 'year', type: 'number', label: 'Informe o ano' },
        ].map(input => (
          <InputHookForm
            name={input.name}
            register={register}
            errors={errors}
            label={input.label}
            type="number"
            w="180px"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            width="180px"
            mr="10px"
          />
        ))}
      </Box>
      <Button bg="#4AC1F0" color="#FCFCFD" w="220px" py="7" fontSize="20px" fontWeight="700">
        Aplicar
      </Button> */}
      <IndicatorsForm />
    </Box>
  );
}
