/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import {
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';

interface SelectInterface {
  name: string | undefined | any;
  label: string | undefined;
  placeholder?: string | undefined;
  control: any;
  errors: any;
  options: any;
  colorPlaceholder?: string;
  icon?: JSX.Element;
  mask?: string;
  maskChar?: string;
  disable?: boolean;
  type?: string;
}

export const CheckboxGroupInputForm = ({
  name,
  label,
  control,
  errors,
  options,
}: SelectInterface) => {
  return (
    <FormControl isInvalid={!!errors[name]} mb={2}>
      <FormLabel fontWeight="bold" mb={2} htmlFor={name}>
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <CheckboxGroup colorScheme="blue" {...rest}>
            {options?.map(checkboxItem => (
              <Checkbox key={checkboxItem.value} ref={ref} mr={4} value={checkboxItem.value}>
                {checkboxItem.label}
              </Checkbox>
            ))}
          </CheckboxGroup>
        )}
      />
      {errors[name] && <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>}
    </FormControl>
  );
};
