/* eslint-disable no-unused-vars */
import React, { ReactText } from 'react';
import {
  Flex,
  FlexProps,
  ScaleFade,
  Text,
  useColorModeValue,
  Spacer,
  Link,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../providers/auth';

interface NavItemProps extends FlexProps {
  href: any;
  children: ReactText;
  isOpen: boolean;
  alternativesPaths?: string[];
}

export function NavItem({ href, children, isOpen, alternativesPaths, ...rest }: NavItemProps) {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const isOnPath =
    alternativesPaths?.includes(
      pathname?.split('/')?.length > 2 ? pathname.split('/')[1] : pathname,
    ) || pathname === href;
  return (
    <Link transition="0.5s ease" maxW={isOpen ? '300px' : '64px'} href={href}>
      <Flex transition="0.2s" cursor="pointer" {...rest}>
        <Flex
          w={isOpen ? '80%' : 'full'}
          // opac={0.1}
          bg={isOnPath && isOpen ? 'hsla(0, 0%, 50%, 0.45)' : ''}
          borderRadius={isOnPath && isOpen ? '0px 27px 27px 0px' : '0px'}
          p={isOpen ? '8px 0 8px 15px' : '8px 0 0px 15px'}
        >
          {!isOpen && (
            <Text
              mt="9px"
              ml="10px"
              fontWeight="bold"
              color={useColorModeValue('text.light.primary', 'text.dark.primary')}
              opacity={isOnPath ? '1' : '0.36'}
            >
              {children[0]}
            </Text>
          )}
          <ScaleFade in={isOpen} initialScale={0.3}>
            <Flex position="relative" _hover={{ '.initialLetter': { display: 'block' } }}>
              {user.group.id > 2 && (
                <Text
                  className="initialLetter"
                  position="absolute"
                  display={isOnPath ? 'block' : 'none'}
                  mt="9px"
                  fontWeight="bold"
                  opacity="0.36"
                  color={useColorModeValue('text.light.primary', 'text.dark.primary')}
                >
                  {children[0]}
                </Text>
              )}

              <Text
                mt="9px"
                ml="30px"
                minW="95px"
                color={useColorModeValue('text.light.primary', 'text.dark.primary')}
                zIndex={999999}
              >
                {children}
              </Text>
            </Flex>
          </ScaleFade>
          <Spacer />
        </Flex>
      </Flex>
    </Link>
  );
}
