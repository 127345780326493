import React from 'react';
// import { useTranslation } from 'react-i18next';
import { DataGrid } from '../../components/datagrid';
import { useFetch } from '../../hooks/useFetch';
import { SearchFilter } from '../../components/inputs/filters/search';
import UnitsForm from '../../components/forms/UnitsForm';

export default function UnitsPage() {
  // const { t } = useTranslation();
  const baseUrl = '/v1/admin/units';
  const { requestApi, data, loading, error } = useFetch(baseUrl);

  const columns = [
    { name: 'client', label: 'Cliente' },
    { name: 'name', label: 'Nome' },
    { name: 'contact_name', label: 'Nome do contato' },
    { name: 'phone', label: 'Telefone' },
    { name: 'comments', label: 'Comentários' },
  ];

  return (
    <div className="App">
      <DataGrid
        columns={columns}
        rows={data?.data}
        requestApi={requestApi}
        error={error}
        baseUrl={baseUrl}
        data={data}
        hasCreate
        createDescription="unidade"
        modalForm={UnitsForm}
        formatColum={[
          {
            field: 'client',
            formatField: value => {
              return value.name;
            },
          },
        ]}
        loading={loading}
        rowActions={{ show: false, edit: true, delete: true }}
        headerFilters={[
          {
            name: 'search',
            filterComponent: SearchFilter,
            placeholder: 'Buscar Unidade',
          },
        ]}
      />
    </div>
  );
}
